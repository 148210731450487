import { OwcListItem, OwcTypography, OwcList, OwcInput, OwcIconButton } from '@one/react';
import { Chart } from "react-google-charts";
import React from 'react';
import '../../styles.scss';
import {configData} from "../../config.js";
import {fetchSigned} from "../../shared/Utilities.js"

export const options = {
  colorAxis: { minValue: 0, maxValue: 1, colors: ["#D3D3D3", "#00853f"] },
  backgroundColor: "#81d4fa",
  datalessRegionColor: "#f5f5f5",
  defaultColor: "#f5f5f5",
  tooltip: { isHtml: true },
  legend: "none",
};

/**
 * Renders a tooltip with a delay.
 *
 * @copyright Roche 2024
 * @author Geoffroy Maillol
 */
class CountrySelector extends React.Component {
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
      super(props);
      this.state = {
        countryData: [],
        countryDataForMap: [],
        countryDataForList: [],
        loading: true,
        error: "",
        searchTerm: null,
      };
  }

  componentDidMount() {
    fetchSigned(configData.USECASE_API_URL + "countries")
    .then(res => res.json())
    .then(
      (countryData) => {
        this.setState({ 
                 countryData,
                 countryDataForMap: this.getMapData(countryData),
                 countryDataForList: countryData,
                 loading: false
               });
      },
      (error) => {
        this.setState({ error, loading: false });
      }
    )
  }

  getMapData(countryData) {
    const countryDataForMap = [["Country", "Selected", { role: 'tooltip', type: 'string', p: { html: true } }]];
    countryData.forEach((country) => {
      if (country.standard === true) { // only include standard country codes for the map
        const selected = this.props.selectedCountryCodes.includes(country["alpha-2"]);
        countryDataForMap.push([
          country["alpha-2"],
          selected ? 1 : 0,
          this.getMapTooltip(country["alpha-2"], selected, countryData),
        ]);
      }
    });
    return countryDataForMap;
  }

  getMapTooltip(countryCode, selected, countryData) {
    const selectedString = selected ? " - Selected": "";
    const countryName = countryData.filter(country => country["alpha-2"] === countryCode).map(country => country.name);
    return `<div>${countryName}${selectedString}</div>`;
  }

  changeMapItemSelection(changedMapItem) {
    changedMapItem[1] = (changedMapItem[1] === 0 ? 1 : 0);
    changedMapItem[2] = this.getMapTooltip(changedMapItem[0], changedMapItem[1] === 1, this.state.countryData);
  }

  handleSelectedChange(selectedCountryCodes) {
    console.log(`Selected countries: ${JSON.stringify(selectedCountryCodes)}`);
    this.props.onSelectedChange(selectedCountryCodes);
  }

  handleMapSelection(chartWrapper) {
    const chart = chartWrapper.getChart();
    const selection = chart.getSelection();
    if (selection.length === 0) return;

    const regionData = this.state.countryDataForMap;
    this.changeMapItemSelection(regionData[selection[0].row + 1]);
    this.handleSelectedChange(regionData.filter(region => region[1] === 1).map(region => region[0]));
  }

  refreshMapSelection(selectedRegions) {
    const regionData = this.state.countryDataForMap;
    regionData.filter(region => region[0] !== "Country")
      .forEach(region => {
        if ((selectedRegions.includes(region[0]) && region[1] === 0)
              || (!selectedRegions.includes(region[0]) && region[1] === 1)) {
          this.changeMapItemSelection(region);
        }
      });
    this.handleSelectedChange(selectedRegions);
  }

  handleSearchValueChange(searchTerm) {
    if (searchTerm && searchTerm !== "") {
      const displayedCountries = this.state.countryData.filter(
        country => country.name.toLowerCase().includes(searchTerm.toLowerCase()));
      this.setState({
        countryDataForList: displayedCountries,
        searchTerm: searchTerm,
      });
    } else {
      this.handleSearchClear();
    }
  }

  handleSearchClear() {
    this.setState({
      countryDataForList: this.state.countryData,
      searchTerm: null,
    });
  }

  renderCountryItem(country) {
    return (
      <OwcListItem key={country["alpha-2"]} id={country["alpha-2"]} 
          size={this.props.size}
          value={country["alpha-2"]} >
        {country.name}
      </OwcListItem>
    );
  }

  renderSubList(groupName, items, isLeaf) {
    if (isLeaf) {
      return (
        <OwcListItem key={"CountryListItem" + groupName}>
          {groupName}
          <OwcList key={"CountrySubList" + groupName} slot="children">
            {items.sort().map(country => this.renderCountryItem(country))}
          </OwcList>
        </OwcListItem>
      );
    } else {
      return (
        <OwcListItem key={"CountryListItem" + groupName}>
          {groupName}
          <OwcList key={"CountrySubList" + groupName} slot="children">
            {(Object.keys(items)).sort().map(region => this.renderSubList(region, items[region], true))}
          </OwcList>
        </OwcListItem>
      );
    }
  }

  groupCountryList() {
    const groupedCountries = {};
    this.state.countryData.forEach(country => {
        const region = country.region? country.region: "Unknown";
        const subRegion = country["sub-region"]? country["sub-region"]: "Unknown";
        if (!groupedCountries[region]) {
            groupedCountries[region] = {};
        }
        if (!groupedCountries[region][subRegion]) {
            groupedCountries[region][subRegion] = [];
        }
        groupedCountries[region][subRegion].push(country);
    });
    return groupedCountries;
  }

  renderGroupedCountryList() {
    const groupedCountries = this.groupCountryList();
    return (
      <OwcList key="GroupedCountryList"
          multiple showSelectorControl 
          selected={this.props.selectedCountryCodes}
          onSelectedChange={(ev) => this.refreshMapSelection(ev.detail)}
          style={{height: "400px", width: "25em", overflowY: "auto"}}>
        
        {
          (Object.keys(groupedCountries)).sort().map(region => this.renderSubList(region, groupedCountries[region], false))
        }
      </OwcList>
    );
  }

  renderCountryList() {
    if (this.state.searchTerm && this.state.searchTerm !== "") {
      return (
        <OwcList  key="CountryList" multiple showSelectorControl 
            selected={this.props.selectedCountryCodes}
            onSelectedChange={(ev) => this.refreshMapSelection(ev.detail)}
            style={{height: "300px", width: "25em", overflowY: "auto"}}>
          {this.state.countryDataForList.map(country => this.renderCountryItem(country))}
        </OwcList>
       );
    } else {
      return this.renderGroupedCountryList();
    }
  }

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    if (!this.state.loading && this.state.countryData) {
      return (
        <div style={{ display:"flex", flexDirection:"row", alignItems:'flex-start', padding:"5px 10px 5px 0px", gap:"15px" }}>
          <div style={{ display:"flex", flexDirection:"column" }}>
            <OwcTypography variant="title6" style={{ padding:"0px 0px 5px 0px" }}>
              {this.props.title ? this.props.title : "Country selection"}
            </OwcTypography>
            <OwcInput
                style={{ width: "100%" }}
                placeholder="Type to search..."
                round={false}
                variant="filled"
                onValueChange={(ev) => this.handleSearchValueChange(ev.detail)}
                onClearClick={(ev) => this.handleSearchClear()}>
              <OwcIconButton slot="prefix" icon="search" />
            </OwcInput>
            {this.renderCountryList()}
          </div>
          <Chart chartType="GeoChart" width="550px" height="445px"
            chartEvents={[
              {
                eventName: "select",
                callback: ({ chartWrapper }) => this.handleMapSelection(chartWrapper),
              },
            ]}
            data={this.state.countryDataForMap}
            options={options}
          />
        </div>
      );
    }
  }
}

CountrySelector.defaultProps = {
  size: 'small',
}

export default CountrySelector
