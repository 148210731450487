import {OwcProgressSpinner } from '@one/react';
import React from 'react';
import {configData} from "../../config.js";
import '../../styles.scss';
import {fetchSigned} from "../../shared/Utilities.js"

/**
 * Renders a read only table of use case details
 *
 * @copyright Roche 2024
 * @author Nick Draper
 */
class SelectedReferenceList extends React.Component {
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {isLoading:true};
  }

  /**
   * Runs once after construction after everything is initialised
   */
  componentDidMount() {
    if (this.props.refListsComplete === undefined || this.props.refListsComplete.length === 0) {
      // load the reference data and split out the affilate list
      fetchSigned(configData.REFDATA_API_URL + "?includeInactive=true")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
              refDataList: result,
              isLoading: false,
            }
          );
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {this.setState({error})}
      );
    } else {
      this.setState({
        refDataList: this.props.refListsComplete,
        isLoading: false,
        });
    }
  }

  /**
   * Gets the description for a reference data id
   * @param {*} value the ref list id value
   * @param {*} refdataList the list to search in
   * @returns the description or null if not found
   */
  getDescriptionForRefData(value, refdataList) {
    let retVal = null;
    if (refdataList !== undefined) {
      const refDataItem = refdataList.find(({ refListId }) => refListId === value);
      //get the record
      if (refDataItem !== undefined) {
        retVal = refDataItem.description;
        if (refDataItem.isActive === false) {
          retVal += " (Deprecated)"
        }
      }
    }
    return retVal;
  }


  renderSelectedItem(id){
    return(
      <tr key={"renderSelectedItemRow"+id}>
        <td key={"renderSelectedItemData"+id}>{this.getDescriptionForRefData(id, this.state.refDataList)}</td>
      </tr>
    );
  }

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    if (this.props.selectedRefDataList !== undefined && this.props.selectedRefDataList !== null) {
      if (this.state.isLoading) {
        return (<OwcProgressSpinner/>);
      } else {
        return (
          <table>
          <tbody>
            {this.props.hideTitle?
              ""
            :
              <tr>
                <td style={{ "fontWeight": "bold" }}>{this.props.title}</td>
              </tr>
            }
            {this.props.selectedRefDataList.map((id)=>this.renderSelectedItem(id))}
          </tbody>
        </table>
        );
      }
    }
  }

  /**
   * looks up a ref data item and returns the string with an optional deprecation warning
   * @param {*} refListIdToLookup The id to look up
   * @param {*} alternateValue if not null this will be used for the ref data value
   * @param {*} includeDeprecationWarning if true and the ref list item is not active add a warning
   * @returns the strinbg desctrption of the ref data item, or null if it cannot be found
   */
  lookupRefData(refListIdToLookup, alternateValue = null, includeDeprecationWarning = true) {
    let retVal = alternateValue;
    if (this.state.isLoading===false) {
      const refDataEntry = this.state.refListsComplete.find(({ refListId }) => refListId === refListIdToLookup);
      if (refDataEntry !== undefined) {
        if (retVal === null) {
          retVal = refDataEntry.description;
        }
        if ((includeDeprecationWarning) && (!refDataEntry.isActive)) {
          retVal += configData.REFDATA_DEPRECATED;
        }
      }
    }
    return (retVal);
  }
}

SelectedReferenceList.defaultProps = {
  selectedRefDataList: [],
  title: "Purpose Classification:",
  refListsComplete: [],
  hideTitle: false,
}

export default SelectedReferenceList;
