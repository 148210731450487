import React from 'react';
import {configData} from "../config.js";
import UseCaseDetails from "../components/useCase/UseCaseDetails.js";
import ApplicableDevices from "../components/useCase/ApplicableDevices.js";
import SelectedReferenceList from '../components/general/SelectedReferenceList.js';
import '../styles.scss';

import {formatDate, fetchSigned} from "../shared/Utilities.js"

import {
  OwcButton, OwcTypography, OwcExpandableGroup, OwcExpandable,
   OwcProgressSpinner, OwcTable, OwcTableHeader, OwcTableHeaderCell,
   OwcTableRow, OwcTableCell, OwcTableBody,
} from '@one/react';

/**
 * The interactive form for reviewing and publishing a use case
 *
 * @copyright Roche 2024
 * @author Nick Draper
 */
class PublishUseCase extends React.Component {
  EDITABLE_VERSION_TEXT = "Current draft version";
  VERSION_PREFIX_TEXT = "Version ";
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.state.refDataListComplete = [];
    if (props.useCaseId !== null)
    {
      this.state.isLoading = true;
    }
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.useCaseId !== this.props.useCaseId) {
      this.loadVersions(this.props.useCaseId, false);
    }
  }

  /**
   * Runs once after construction after everything is initialised
   */
  componentDidMount() {
    // load the reference data and split out the affilate list
    fetchSigned(configData.REFDATA_API_URL + "?includeInactive=true")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
              refDataListComplete: result,
            },
            this.loadVersions(this.props.useCaseId)
          );
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {this.setState({error})}
      );
  }

  /**
   * Sets the initial state
   * @returns the initial state dictionary
   */
  initialState() {
    return ({
      isLoading: false,
      useCase:{useCaseId:-1, name:"", contactEmail:null,
        description: null,
        dataDescription: null,
        createdBy: null,
        dateCreated: null,
        dateUpdated: null,
      },
      selectedVersion: 0,
      includedDeviceList: [],
      errorList: [],
      warningList: [],
      versions: [],
      maxVersion: 0,
      submissionState: null,
      isUseCaseExpanded: true,
      isMappedEntitlementsExpanded: true,
      isValidationResultsExpanded: true,
    });
  }

  /**
     * Loads the list of versions of this use case
     * @param {*} useCaseId the selected use case id
     */
  loadVersions(useCaseId){
    //get the agreement versions
    fetchSigned(configData.USECASE_API_URL + `versions/${useCaseId}/`)
    .then(res => res.json())
    .then(
      (result) => {
        const versionNoList = result.map(({versionNo}) => versionNo);
        const maxVersion = versionNoList.length > 0? Math.max(...versionNoList)  : 0;
        this.setState({ versions: result, maxVersion: maxVersion},
          ()=>this.loadUseCaseData(this.props.useCaseId, false)
        );
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          error
        });
      }
    )
  }

  /**
   * Loads a useCase
   * @param {*} ev the event
   * @param {*} useCaseId  the selected agreeemntId
   */
  loadUseCaseData(useCaseId, clearSubmissionState = true) { 
    this.setState({
      isLoading:true,
      errorList: [],
      warningList: [],
    });
    const versionNo = this.state.selectedVersion;
    console.log(`Selected use case id: ${useCaseId} version ${versionNo}`);

    if (useCaseId !== null) {
      fetchSigned(`${configData.USECASE_API_URL}version/?useCaseId=${useCaseId}&versionNo=${versionNo}`)
        .then(res => res.json())
        .then(
          (result) => {
            result.applicableDevices = [];
            const newState = {useCase: result,
              isLoading: false
            }; 
            if (clearSubmissionState) {
              newState.submissionState = null;
            }
            this.setState(newState, () => this.checkValidation());
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => { this.setState({error}) }
      );
    } else {
      this.setState(this.initialState());
    };    
  }

  /**
 * handles the change of the version selection box
 * @param {*} newVersionText 
 */
  handleVersionChange(newVersionText){
    if (newVersionText !== `${this.VERSION_PREFIX_TEXT}${this.state.selectedVersion}`)
    {
      // extract the version number
      let newVersionInt = parseInt(newVersionText.slice(this.VERSION_PREFIX_TEXT.length));
      if (newVersionText === this.EDITABLE_VERSION_TEXT)
      {
        newVersionInt = 0;
      }
      this.setState({ selectedVersion: newVersionInt}, 
        ()=>this.loadUseCaseData(this.props.useCaseId, true));
    }
  }

  /**
   * Validates the form and submits it to the API if valid
   * @returns nothing
   */
  handleSubmitClick() {
    // check the form is valid
    const submissionData = {
      useCaseId: this.props.useCaseId,
      publishedBy: this.props.userName,
    };

    this.setState({ submissionState: "Publishing ..." });

    const submitForm = () => {
      // decide if it is an update or insert and setup appropriately
      return fetchSigned(configData.USECASE_API_URL + "publish/", {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submissionData)
      })
        .then((response) => {
          console.log(response.status);
          if (response.status === 201) // inserted successfully
          {
            response.json().then((json) => {
              console.log("API Response" + JSON.stringify(json));
              const newVersionNo = parseInt(json.versionNo);
              this.setState({ submissionState: `Version ${newVersionNo} successfully published`,
                              selectedVersion: newVersionNo}, () => this.loadVersions(this.props.useCaseId))
            }).catch((error) => {
              this.setState({ submissionState: "Error publishing version " + error });
              console.error(error);
            });
          } else {
            response.json().then((json) => {
              console.log("API Response" + JSON.stringify(json));
              this.setState({ submissionState: "Error publishing version " + json.errorText });
            }).catch((error) => {
              this.setState({ submissionState: "Error publishing version " + error })
              console.error(error);
            });
          }
        });
    };
    submitForm();

  };

  /** Check all the validation rules against the loaded data 
   * and enters warnings and errors into the state 
   */
  checkValidation(){
    if (this.state.isLoading === false && this.state.selectedVersion === 0)
      {
        const useCase = this.state.useCase;
        const warnings = [];
        const errors = [];

        // errors
        if (useCase.dataAccessApproved === null){
          errors.push({tab: configData.TAB_RECORD_DECISIONS_USE_CASES,
            error:`No Data Access Approval decision recorded`});
        }
        if (useCase.dataAccessApproved === true && useCase.dataEntitlementsEnforced === null){
          errors.push({tab: configData.TAB_RECORD_DECISIONS_USE_CASES,
            error:`Data Access Approved, but no Data Entitlements decision recorded`});
        }

        // warnings
        const notNullWarningsFields = [
          {page: configData.TAB_REGISTER_USE_CASES, field:"contactEmail", text:"Contact Email"},
          {page: configData.TAB_REGISTER_USE_CASES, field:"description", text:"Description"},
          {page: configData.TAB_CLASSIFY_USE_CASES, field:"dataNeeds", text:"Data Needs Classification"},
        ];
        notNullWarningsFields.forEach(warningCheck => {
          if (useCase[warningCheck.field] === null || 
            useCase[warningCheck.field] === undefined || 
            useCase[warningCheck.field].trim() === "")
          {
              warnings.push({tab: warningCheck.page,
                            warning:`The ${warningCheck.text} should be set for the use case`});
          }
        });

        if (useCase.purposeList === null || useCase.purposeList.length === 0){
          warnings.push({tab: configData.TAB_CLASSIFY_USE_CASES,
            warning:`No Purposes classified`});
        }

        if (useCase.dataAccessApproved === true && (useCase.countryList === null || useCase.countryList.length === 0)){
          warnings.push({tab: configData.TAB_CLASSIFY_USE_CASES,
            warning:`Data Access Approved but no Countries classified (will result in 0 included devices)`});
        }

        if (useCase.dataAccessApproved === true && (useCase.systemTypeList === null || useCase.systemTypeList.length === 0)){
          warnings.push({tab: configData.TAB_CLASSIFY_USE_CASES,
            warning:`Data Access Approved but no System Types classified (will result in 0 included devices)`});
        }

        if (useCase.dataAccessApproved === true && useCase.informCustomers === null){
          warnings.push({tab: configData.TAB_RECORD_DECISIONS_USE_CASES,
            warning:`Data Access approved, but no Customers to be informed decision recorded`});
        }
        
        if (useCase.dataAccessApproved === true 
            && useCase.dataEntitlementsEnforced === true 
            && useCase.dataUseList.length + useCase.processingActionList.length === 0){
          warnings.push({tab: configData.TAB_MAPPING_USE_CASES,
            warning:`Data Entitlements Enforced but no Data Entitlements mapped (will result in 0 included devices)`});
        }

        if (useCase.dataAccessApproved === true 
          && useCase.dataEntitlementsEnforced === false 
          && (useCase.dataUseList.length + useCase.processingActionList.length) > 0){
        warnings.push({tab: configData.TAB_MAPPING_USE_CASES,
          warning:`Data Entitlements Not Enforced but Data Entitlements are mapped (entitlements will be ignored)`});
      }

        this.setState({errorList: errors, 
          warningList: warnings});
      }
  }

  renderValidationResultsSection() {
    if (this.state.selectedVersion === 0)
    {
      return (
        <div style={{display:"flex", flexDirection: "column", width:"100%", marginLeft:"1em"}}>
        <hr/>
          <OwcTypography variant="title5" style={{marginLeft:"0.7em", marginBottom: "0.5em"}}>
            -&nbsp;&nbsp;&nbsp;&nbsp;
            Validation Results{` - ${this.state.errorList.length} error${this.state.errorList.length===1?"":"s"}, `}
                              {`${this.state.warningList.length} warning${this.state.warningList.length===1?"":"s"}`}
          </OwcTypography>
          <div>
            {this.state.errorList.length>0?
              <OwcTable style={{ marginBottom: "0.5em"}} size="dense" height="auto" >
                <OwcTableHeader elevated sticky>
                  <OwcTableHeaderCell width="20%" resizable>Tab to make changes</OwcTableHeaderCell>
                  <OwcTableHeaderCell width="80%" resizable>Error</OwcTableHeaderCell>
                </OwcTableHeader>
                <OwcTableBody>
                  {this.state.errorList.map((error, index) => (
                    <OwcTableRow key={"Row" + index}>
                      <OwcTableCell key={"Tab" + index} style={{wordBreak:"break-word"}} valign="top">{error.tab}</OwcTableCell>
                      <OwcTableCell key={"Text" + index} style={{wordBreak:"break-word"}} valign="top">{error.error}</OwcTableCell>
                    </OwcTableRow>
                  ))}
                </OwcTableBody>
                <div slot="header" style={{ alignItems: 'center' }}>
                  <label style={{ flexGrow: 1, fontWeight:"bold", color:"red"}}>Errors preventing publishing a new version</label>
                </div>
              </OwcTable>
            : <div style={{boxShadow: "var(--one-elevation-box-shadow-8)", padding: "4px 16px"}}>
                <OwcTypography style={{fontWeight:"bold", color:"Grey"}}>No Errors</OwcTypography>
              </div>
            }
            <br />
            {this.state.warningList.length>0?
            <OwcTable style={{ marginBottom: "0.5em" }} size='dense' height="auto" >
              <OwcTableHeader elevated sticky>
                <OwcTableHeaderCell width="20%" resizable>Tab to make changes</OwcTableHeaderCell>
                <OwcTableHeaderCell width="80%" resizable>Warning</OwcTableHeaderCell>
              </OwcTableHeader>
              <OwcTableBody>
                {this.state.warningList.map((warning, index) => (
                  <OwcTableRow key={"Row" + index}>
                    <OwcTableCell key={"Tab" + index} style={{wordBreak:"break-word"}} valign="top">{warning.tab}</OwcTableCell>
                    <OwcTableCell key={"Text" + index} style={{wordBreak:"break-word"}} valign="top">{warning.warning}</OwcTableCell>
                  </OwcTableRow>
                ))}
              </OwcTableBody>
              <div slot="header" style={{ alignItems: 'center' }}>
                <label style={{ flexGrow: 1, fontWeight:"bold", color: "orange"}}>Warnings (will not prevent publishing a new version)</label>
              </div>
            </OwcTable>
            : <div style={{boxShadow: "var(--one-elevation-box-shadow-8)", padding: "4px 16px"}}>
                <OwcTypography style={{fontWeight:"bold", color:"Grey"}}>No Warnings</OwcTypography>
              </div>
            }
          </div>
        </div>
      );
    }
  }

  renderMappedEntitlementsSection() {
    return (
      <OwcExpandable variant="standard" round
          expanded={this.state.isMappedEntitlementsExpanded}
          onExpandedChange={(ev) => this.setState({ isMappedEntitlementsExpanded: ev.detail })}
          disabled={this.state.useCase.dataAccessApproved === false || this.state.useCase.dataEntitlementsEnforced === false}>
        <span slot="title">
          <OwcTypography variant="title5">
            {`Mapped Entitlements - ${this.state.useCase.dataUseList.length + this.state.useCase.processingActionList.length} Selected` }
          </OwcTypography>
        </span>
        <span slot="content">
          <SelectedReferenceList selectedRefDataList={this.state.useCase.dataUseList} title="Data Use:" refListsComplete={this.state.refDataListComplete}/>
          <SelectedReferenceList selectedRefDataList={this.state.useCase.processingActionList} title="Processing Action:" refListsComplete={this.state.refDataListComplete}/>
        </span>
      </OwcExpandable>
    );
  }

  
  /**
   * Reenders the submit button and associated submission state
   * @returns The JSX of the controls
   */
renderSubmitButton() {
  let messageColour = "black";
  if (this.state.submissionState !== null && this.state.submissionState.startsWith("Err")) {
    messageColour = "red";
  };
  return (
    <>
      <div style={{display: "flex", justifyContent: "flex-end"}}>
      <OwcButton style={{ width: "fit-content" }}
        onclick={() => this.handleSubmitClick()}
        disabled={((this.state.submissionState === "Publishing ...") 
                    || (this.state.errorList.length !== 0)
                    || (this.state.selectedVersion !== 0)) ? true : false}
      >
      {this.state.submissionState === "Publishing ..." ? this.state.submissionState : "Publish version " + (this.state.maxVersion + 1)}
      </OwcButton>
      </div>
      <OwcTypography variant="title6" style={{ marginBottom: 8, textAlign: "right", color: messageColour }}>
        {this.state.submissionState === "Publishing ..." ? "" : this.state.submissionState}
      </OwcTypography>
    </>
  );
}

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    if (this.props.useCaseId === null) {
      return (<OwcTypography variant="title6" style={{marginLeft:"0.5em"}}>Save an use case in "Register" first</OwcTypography>);
    } else {
      if (this.state.isLoading){
        return (
          <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
            <OwcProgressSpinner style={{marginTop:'30px'}}/>
          </div>);
      } else {
        let useCaseVersionCorrected = this.state.useCase;
        if (this.state.selectedVersion === 0) {
          useCaseVersionCorrected = {...this.state.useCase};
          useCaseVersionCorrected.versionNo = null;
        }
        return (
          <div>
            <div style={{display:"flex", width:"100%", justifyContent:"space-between", alignItems:"baseline"}}>
              <div>
                <OwcTypography variant="subtitle2" style={{marginLeft: "1em"}}>Verify & Publish</OwcTypography>
              </div>
              <div>
                <OwcTypography variant="subtitle2">
                  Version
                </OwcTypography>&nbsp;
                <select 
                  value={this.state.selectedVersion === 0? 
                    this.EDITABLE_VERSION_TEXT
                    : this.VERSION_PREFIX_TEXT + this.state.selectedVersion}
                  onChange={(ev) => { this.handleVersionChange(ev.target.value)}}
                >
                  <option>{this.EDITABLE_VERSION_TEXT}</option>
                  {this.state.versions.slice(0).reverse().map(version => (
                    <option key={version.versionNo} >{this.VERSION_PREFIX_TEXT}{version.versionNo}</option>
                  ))}
                </select>
                {this.state.selectedVersion === 0 
                  ? <> </>
                  :
                    <>
                    <br/>
                    <OwcTypography variant="subtitle2">Published on {formatDate(this.state.useCase.datePublished, true)}</OwcTypography>
                    </>
                }
              </div>           
            </div>
            <OwcExpandableGroup multiple>
            <OwcExpandable variant="standard" round
                expanded={this.state.isUseCaseExpanded}
                onExpandedChange={(ev) => {console.log(ev);this.setState({ isUseCaseExpanded: ev.detail })}} >
              <span style={{display:"flex", width:"100%", justifyContent:"space-between", alignItems:"baseline"}} slot="title">
                <OwcTypography variant="title5">
                  {!this.state.isUseCaseExpanded? `Use Case ${this.state.useCase.useCaseId} - ${this.state.useCase.name}` : `Use Case ${this.state.useCase.useCaseId}`}
                </OwcTypography>
              </span>
              <span slot="content">
                  <UseCaseDetails useCase={this.state.useCase}/>
                  <SelectedReferenceList selectedRefDataList={this.state.useCase.purposeList} title="Purpose Classification:" refListsComplete={this.state.refDataListComplete}/>
              </span>
            </OwcExpandable>
            {this.renderMappedEntitlementsSection()}         
            <ApplicableDevices key="ApplicableDevies" useCase={useCaseVersionCorrected}/>
            {this.renderValidationResultsSection()}

            </OwcExpandableGroup>
            {this.renderSubmitButton()}
          </div>
        );
      }
    }
  }
}

export default PublishUseCase;
