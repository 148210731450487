import React from 'react';
import '../styles.scss';
import {configData} from "../config.js";
import {formatDate, convertToDate, fetchSigned} from "../shared/Utilities.js"
import DelayedTooltip from "../components/general/DelayedTooltip.js";

import {
  OwcTabs, OwcTab, OwcTypography, OwcTable, OwcTableHeader,
  OwcTableCell, OwcTableHeaderCell, OwcTableRow, OwcTableBody,
  OwcInput, OwcButton, OwcCheckbox, OwcProgressSpinner
} from '@one/react';

/**
 * The Control for managing reference lists
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */

class ReferenceLists extends React.Component {
  UNSAVED_CHANGES_MESSAGE = "Unsaved changes, click Save Changes to save";

  constructor(props) {
    super(props);
    this.state = {
      structuredReferenceLists: {},
      refListTypes: [],
      selectedListType: null,
      inputValue: "",
      submissionState: null,
      inputError: null,
      usageCounts: {},
      usageCountDescription: {},
      refDataHistory: {},
      showDeprecated: true,
    }
  }

  /**
     * Runs one after construction after everything is initialised
     */
  componentDidMount() {
    // load the reference data types
    fetchSigned(configData.REFDATA_API_URL + "types/").then(res => res.json())  
      .then(
        (result) => {
          this.setState({
            refListTypes: result,
            selectedListType: result[0]
          },
            this.loadRefData());
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      );

    // load the reference data usage
    fetchSigned(configData.REFDATA_API_URL + "usage/").then(res => res.json())
      .then(
        (result) => {
          const usageCounts = {};
          result.forEach(usageItem => {
            usageCounts[usageItem.refListId] = usageItem.uses;
          });
          this.setState({
            usageCounts: usageCounts
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      );

    // load the reference data usage labels where they differ from the default
    fetchSigned(configData.REFDATA_API_URL + "usagelabel/")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            usageCountDescription: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      );
  }

  /**
   * Loads the reference data and splits it into seperate lists
   */
  loadRefData() {
    // load the reference data and split into seperate lists
    fetchSigned(configData.REFDATA_API_URL + "?includeInactive=true")
      .then(res => res.json())
      .then(
        (result) => {
          const structuredRefData = {};
          this.state.refListTypes.forEach((typeName) => {
            structuredRefData[typeName] = result.filter(value =>
              value.type === typeName
            );
          });
          this.setState({ structuredReferenceLists: structuredRefData });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )
  }

  /**
   * Loads the ref data history and changes for the selected ref list id
   * @param {*} selectedRefListId the ref list id to load the history for
   */
  loadRefDataHistory(selectedRefListId) {
    fetchSigned(configData.REFDATA_API_URL + `history/${selectedRefListId}/`)
      .then(res => res.json())
      .then(
        (result) => {
          const updatedHistory = {...this.state.refDataHistory};
          updatedHistory[selectedRefListId] = result;
          this.setState({ refDataHistory: updatedHistory });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )
  }

  /**
   * Handles changes between the tabs of the different lists
   * @param {*} tabName The selected tab name
   */
  handleTabChange(tabName) {
    if (tabName !== this.state.selectedListType)
    {
      this.setState({ 
        inputValue: "",
        selectedListType: tabName,
        selectedIndex: null,
      });
    }
  }

  /**
   * handles changes to the checkbox of a refDataItem
   * @param {*} ev the event
   * @param {*} index the index in the selected list
   */
  handleCheckboxChange(ev, index) {
    const allLists = {...this.state.structuredReferenceLists};
    const selectedList = allLists[this.state.selectedListType];
    //note the ev.detail is the opposite of isActive
    if (selectedList[index].isActive === ev.detail) {
      selectedList[index].isActive = !ev.detail;
      selectedList[index].changed = true;
      selectedList[index].dateUpdated = new Date(Date.now());
      selectedList[index].createdBy = this.props.userName;
      this.setState({
        structuredReferenceLists:allLists,
        submissionState: this.UNSAVED_CHANGES_MESSAGE
      });
      this.props.onUnsavedChangesChange(true);
    }
  }

  /**
   * handles changes to the comment of a refDataItem
   * @param {*} ev the event
   * @param {*} index the index in the selected list
   */
  handleCommentChange(ev, index) {
    const allLists = {...this.state.structuredReferenceLists};
    const selectedList = allLists[this.state.selectedListType];
    if (selectedList[index].comment !== ev.detail) {
      selectedList[index].comment = ev.detail;
      selectedList[index].changed = true;
      selectedList[index].dateUpdated = Date.now();
      selectedList[index].createdBy = this.props.userName;
      this.setState({ structuredReferenceLists:allLists,
                      submissionState: this.UNSAVED_CHANGES_MESSAGE});
      this.props.onUnsavedChangesChange(true);
    }
  }

  /**
   * Handles change to the new value text box
   * @param {*} inputValue The new value
   */
  handleInputChange(inputValue) {
    const newState = { inputValue: inputValue };
    const comparisonText = inputValue.trim().toLowerCase();
    const duplicateEntry = this.state.structuredReferenceLists[this.state.selectedListType].find(
      refDataItem => {return refDataItem.description.toLowerCase() === comparisonText});

    if (duplicateEntry !== undefined) {
      newState.inputError = "Duplicate value already exists";
    } else {
      newState.inputError = null;
    }
    this.setState(newState);
  }

  /**
   * Handles the add new button click event
   */
  handleAddNewClick() {
    const newValue = this.state.inputValue.trim();
    const allLists = {...this.state.structuredReferenceLists};
    const selectedList = allLists[this.state.selectedListType];
    selectedList.push({
      refListId: -Object.keys(allLists).length,
      description:newValue,
      isActive:true,
      comment:null,
      dateCreated:Date.now(),
      dateUpdated:Date.now(),
      type:this.state.selectedListType,
      createdBy: this.props.userName,
      changed:true
    });
    this.setState({
      inputValue: "", 
      structuredReferenceLists:allLists,
      submissionState: this.UNSAVED_CHANGES_MESSAGE
    });
    this.props.onUnsavedChangesChange(true);
  }

  /**
   * Handles the expansion of the history section of a reference list item
   * @param {*} ev The event object
   * @param {*} expandedRefListId The id of the expanded item
   */
  handleExpandChange(ev, expandedRefListId) {
    if (ev.detail === true) {
      // opening the detail section
      this.loadRefDataHistory(expandedRefListId);
    }
  }

  /**
   * Handles click of the clear unsaved changes button
   */
  handleCancelClick() {
    this.setState({
        inputValue: "",
        submissionState: null,
        structuredReferenceLists: [],
        inputError: null,
      },
      this.loadRefData()
    );
    this.props.onUnsavedChangesChange(false);
  }

  /**
   * Simple delay function
   * @param {*} milliseconds The number of seconds to wait for
   * @returns A promise that will resolve after the delay
   */
  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  /**
   * Handles submitting changes to the database
   */
  handleSubmitClick() {
    // introduce a short sleep to allow updates to the comments from the table to update the state
    this.sleep(200).then(r => {
      let changedRecords = [];
      this.state.refListTypes.forEach((typeName) => {
        const filteredlist = this.state.structuredReferenceLists[typeName].filter(value =>
          value.changed === true
        );
        if (filteredlist) {
          changedRecords = changedRecords.concat(filteredlist);
        };
      });
      // convert the dates in the records
      changedRecords.forEach((refDataItem) => {
        refDataItem.dateCreated = convertToDate(refDataItem.dateCreated);
        refDataItem.dateUpdated = convertToDate(refDataItem.dateUpdated);
      });
      const submissionData = {
        refData: changedRecords
      };

      this.setState({ submissionState: "Saving ..." });

      const submitForm = () => {
        // decide if it is an update or insert and setup appropriately
        return fetchSigned(configData.REFDATA_API_URL, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(submissionData)
        })
          .then((response) => {
            console.log(response.status);
            if (response.status === 201) // inserted successfully
            {
              response.json().then((json) => {
                this.setState({ inputValue: "",
                                submissionState: "Changes Successfully Saved",
                                }, this.loadRefData());
                this.props.onUnsavedChangesChange(false);
                console.log("API Response" + JSON.stringify(json));
              }).catch((error) => {
                this.setState({ submissionState: "Error saving changes " + error });
                console.error(error);
              });
            } else {
              response.json().then((json) => {
                console.log("API Response" + JSON.stringify(json));
                this.setState({ submissionState: "Error saving changes " + json.errorText });
              }).catch((error) => {
                this.setState({ submissionState: "Error saving changes " + error })
                console.error(error);
              });
            }
          });
      };
      submitForm();
    })
  }

  /**
   * Renders the tab for the selcted refernce data list
   * @returns The JSX of the controls
   */
  renderSelectedList() {
    const selectedList = this.state.structuredReferenceLists[this.state.selectedListType];
    let messageColour = "black";
    if (this.state.submissionState !== null && this.state.submissionState.startsWith("Err")) {
      messageColour = "red";
    }

    if (selectedList) {
      return (
        <div style={{ marginLeft:"0.5em", marginRight:"0.1em", display:"flex", flexDirection:"column" }}>
          <div style={{ display: "flex", flexDirection: "row", verticalAlign: "middle"}}>
            <OwcTypography style={{ marginTop:"0.5em", marginRight:"1em" }}>Add New {this.state.selectedListType}</OwcTypography>
            <OwcInput autoFocus
                onValueChange={(ev) => this.handleInputChange(ev.detail)}
                placeholder="Value" value={this.state.inputValue}
                error={this.state.inputError !== null}>
              <span slot="assistive-text">{this.state.inputError}</span>
            </OwcInput>
            <OwcButton style={{ width: "fit-content",  height: "fit-content", marginLeft:"1em" }} 
              disabled={(!this.state.inputValue.trim() || this.state.inputError !== null)}
              onclick={() => this.handleAddNewClick()}>
              Add to list
            </OwcButton>
            <div style={{marginLeft: "auto", marginRight: "0"}}>
              <OwcCheckbox 
                  checked={this.state.showDeprecated} 
                  onValueChange={(ev)=> this.setState({showDeprecated: ev.detail})}>
                <OwcTypography>Show deprecated values</OwcTypography>
              </OwcCheckbox>
            </div>
          </div>
          <br />
          {this.renderTable()}
          <br />
          <table width="100%">
            <tbody>
              <tr>
                <td align="left">
                  <OwcButton elevated style={{ width: "fit-content" }}
                      onclick={() => this.handleCancelClick()}>
                    Clear Unsaved Changes
                  </OwcButton>
                </td>
                <td align="right">
                  <OwcButton style={{ width: "fit-content" }}
                      onclick={() => this.handleSubmitClick()}
                      disabled={
                        ((this.state.submissionState === "Saving ...") ||
                        (this.state.submissionState === "Changes Successfully Saved") ||
                        (this.state.submissionState === null)) 
                        ? true : false
                      }>
                    {this.state.submissionState === "Saving ..." ? this.state.submissionState : "Save Changes"}
                  </OwcButton>
                </td>
              </tr>
            </tbody>
          </table>
          <OwcTypography variant="title6" style={{ marginBottom: 8, textAlign: "right", color: messageColour }}>
            {this.state.submissionState === "Saving ..." ? "" : this.state.submissionState}
          </OwcTypography>
        </div>
      );
    }
  }
  
  /**
   * Renders the ref list history
   * @returns The JSX of the controls
   */
  renderHistory(refListItem) {
    const refHistory = this.state.refDataHistory[refListItem.refListId];
    return (
      <div key={"historyTableDiv"} style={{display:"block", alignItems: "stretch", width:"90%"}}>
        <table key={"historyTable" + refListItem.refListId}  style={{marginLeft:"5%"}}>
          <caption style={{textAlign:"left"}}><OwcTypography style={{fontWeight:"bold"}}>Change History</OwcTypography></caption>
          <thead>
            <tr align="left" style={{ borderBottom: "1px solid #ddd", backgroundColor: "#eee" }}>
              <th style={{width:"7em"}}>Deprecated</th>
              <th style={{width:"70%", paddingRight:"1em"}}>Comment</th>
              <th style={{width:"10em"}}>Changed By</th>
              <th style={{width:"15%"}}>Date Recorded</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ borderBottom: "1px solid #ddd"}}>
              <td>{refListItem.isActive?"false":"true"}</td>
              <td>{refListItem.comment}</td>
              <td>{refListItem.createdBy}</td>
              <td>{formatDate(refListItem.dateUpdated, true)}</td>
            </tr>
            {
              refHistory.map((historyRecord, index) => (
                <tr key={"HistRow" + historyRecord.refListId + historyRecord.dateUpdated} 
                    style={{ borderBottom: "1px solid #ddd"}}>
                  <td key={"HistDepCell" + historyRecord.refListId + historyRecord.dateUpdated} >
                    {historyRecord.isActive?"false":"true"}</td>
                  <td key={"HistCommentCell" + historyRecord.refListId + historyRecord.dateUpdated} >
                    {historyRecord.comment}</td>
                  <td key={"HistCrByCell" + historyRecord.refListId + historyRecord.dateUpdated} >
                    {historyRecord.createdBy}</td>
                  <td key={"HistDateCell" + historyRecord.refListId + historyRecord.dateUpdated} >
                    {formatDate(historyRecord.dateUpdated, true)}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    );
  }

  /**
   * Renders the ref list table control
   * @returns The JSX of the controls
   */
  renderTable() {
    const selectedList = this.state.structuredReferenceLists[this.state.selectedListType];

    let usedByDescription = "Number of appearances of the value in latest published agreements or use cases";
    if (this.state.selectedListType in this.state.usageCountDescription) {
      usedByDescription = this.state.usageCountDescription[this.state.selectedListType];
    }

    return (
      <OwcTable key={"refListTable" + this.state.selectedListType} 
          style={{ display: "block", minWidth:"50%", maxWidth:"100%" }}
          size='default' showRowExpanderColumn>
        <OwcTableHeader elevated sticky shrink>
          <OwcTableHeaderCell width="30%" resizable id="tooltip-value">Value</OwcTableHeaderCell>
          <DelayedTooltip anchor="tooltip-value" placement="bottom">The text for this refence list entry</DelayedTooltip>
          <OwcTableHeaderCell width="9em" resizable id="tooltip-deprecated">Deprecated</OwcTableHeaderCell>
          <DelayedTooltip anchor="tooltip-deprecated" placement="bottom">
            If an item is deprecated it should not be used any more, but will not be automatically removed from agreements where it is used.
            Deprecated values that are used are indicated with {configData.REFDATA_DEPRECATED} after the value.
          </DelayedTooltip>
          <OwcTableHeaderCell width="7em" resizable id="tooltip-used-by">Used By</OwcTableHeaderCell>
          <DelayedTooltip anchor="tooltip-used-by" placement="bottom">{usedByDescription}</DelayedTooltip>
          <OwcTableHeaderCell width="15%" resizable id="tooltip-date-created">Date Created</OwcTableHeaderCell>
          <DelayedTooltip anchor="tooltip-date-created" placement="bottom">
            The date and time this reference data item was first created
          </DelayedTooltip>
          <OwcTableHeaderCell width="15%" resizable id="tooltip-last-modified">Date Updated</OwcTableHeaderCell>
          <DelayedTooltip anchor="tooltip-last-modified" placement="bottom">
            The date and time this reference data item was last modified
          </DelayedTooltip>
          <OwcTableHeaderCell  resizable id="tooltip-comment">Comment (double click to edit)</OwcTableHeaderCell>
          <DelayedTooltip anchor="tooltip-comment" placement="bottom">
            Comment text associated with this rerence data item, this could include reasons for deprecating a value etc.
            Double click the cell to edit.
          </DelayedTooltip>
        </OwcTableHeader>
        <OwcTableBody>
          {selectedList.map((refListItem, index) => this.renderTableRow(refListItem, index))}
        </OwcTableBody>
      </OwcTable>
    );
    
  }

  /**
   * Rnders a row for a specific reference data item
   * @param {*} refListItem The data for the reference list item
   * @param {*} index the index number for this item within the table
   * @returns The JSX of the controls
   */
  renderTableRow(refListItem, index) {
    if (this.state.showDeprecated === false && refListItem.isActive === false) {
      return;
    } else {
      return (
        <OwcTableRow key={"row" + refListItem.refListId} expandable
            onExpandedChange={ev => this.handleExpandChange(ev, refListItem.refListId)}>
          <OwcTableCell key={"descriptionCell" + refListItem.refListId}
              style={{wordBreak:"break-word"}} valign="middle">
            {refListItem.description}
          </OwcTableCell>
          <OwcTableCell key={"deprecatedCell" + refListItem.refListId} valign="middle">
            <OwcCheckbox checked={!refListItem.isActive} 
              onValueChange={(ev)=> this.handleCheckboxChange(ev, index)} />
          </OwcTableCell>
          
          <OwcTableCell key={"usedByCell" + refListItem.refListId} valign="middle">
            {refListItem.refListId in this.state.usageCounts? this.state.usageCounts[refListItem.refListId]:0}
          </OwcTableCell>
          <OwcTableCell key={"dateCreatedCell" + refListItem.refListId} valign="middle">{formatDate(refListItem.dateCreated, true)}</OwcTableCell>
          <OwcTableCell key={"dateUpdatedCell" + refListItem.refListId} valign="middle">{formatDate(refListItem.dateUpdated, true)}</OwcTableCell>
          <OwcTableCell key={"commentCell" + refListItem.refListId}
              style={{wordBreak:"break-word"}}  editable valign="middle"
              onContentChange={(ev) => this.handleCommentChange(ev, index)}>{refListItem.comment}
          </OwcTableCell>
          <div key={"expandedRow" +  refListItem.refListId} slot="expanded">
            {
              refListItem.refListId in this.state.refDataHistory
              ?this.renderHistory(refListItem)
              :<div style={{width:"100%", paddingLeft: "50%", paddingRight: "50%"}}>
                 <OwcProgressSpinner></OwcProgressSpinner>
               </div>
            }
          </div>
        </OwcTableRow>
      );
    }
  }

  /**
   * Renders the page
   * @returns The JSX of the controls
   */
  render() {
    return (
      <>
        <OwcTabs value={this.state.selectedListType} onValueChange={(ev) => this.handleTabChange(ev.detail)}>
          {this.state.refListTypes.map(typeName => (
            <OwcTab key={"Tab" + typeName} value={typeName}>
              <span><OwcTypography>{typeName}</OwcTypography></span>
            </OwcTab>
          ))}
        </OwcTabs>
        <div name="tabContents" className="tabContents">
          {this.renderSelectedList()}
        </div>
      </>
    )
  }
}

export default ReferenceLists;
