import { OwcTypography, OwcIcon } from '@one/react';
import React from 'react';
import DelayedTooltip from "../general/DelayedTooltip.js";
import {configData} from "../../config.js";
import '../../styles.scss';

/**
 * Renders a read only table of use case details
 *
 * @copyright Roche 2024
 * @author Nick Draper
 */
class UseCaseDetails extends React.Component {

  renderDecision(name, value, tooltipList){
    const keyEnding = name.replace(/\s/g, '');
    let iconName = "circle_help";
    let iconColour = "var(--one-color-orange-600)";
    let tooltip = tooltipList[1];
    if (value !== null) {
      if (value === true) {
        iconName = "circle_confirm";
        iconColour = "var(--one-color-green-500)";
        tooltip = tooltipList[0];
      } else {
        iconName = "circle_clear";
        iconColour = "var(--one-color-red-500)";
        tooltip = tooltipList[2];
      }
    }
    return( 
      <>
      <td style={{ "fontWeight": "bold", width: "1%", whiteSpace: "nowrap" }}>{name}:</td>
      <td style={{ width: "1%", whiteSpace: "nowrap" }}>
        <OwcIcon key={"iconStatus" + keyEnding} 
          id={"iconStatus" + keyEnding} 
          name={iconName} family="filled"
          style={{fontSize:"1.5em", color: iconColour}} />
        <DelayedTooltip key={"iconStatusTooltip" + keyEnding} anchor={"iconStatus" + keyEnding}>
          {tooltip}</DelayedTooltip>
      </td>
      </>
    );
  }

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    if (this.props.useCase !== undefined) {
      const nCols = this.props.hideDecisions === false?4:2;
      return (
        <table>
          {this.props.hideTitle?
            ""
          :
            <caption style={{ textAlign: "left", paddingLeft: "0.15em", marginBottom:"0.25em" }}>
              <OwcTypography style={{ fontWeight: "bold" }}>Details from the Use Case</OwcTypography>
            </caption>
          }
          <tbody>
            <tr>
              <td width="10%" style={{ "fontWeight": "bold" }}>Name:</td>
              <td width="40%">{this.props.useCase.name}</td>
              {this.props.hideDecisions === false?
                this.renderDecision("Data Access Approved", this.props.useCase.dataAccessApproved, 
                                    [ "Data access approved",
                                      "Data access decision is not recorded",
                                      "Data access denied"
                                    ])
              :
                <><td></td><td></td></>
              }
            </tr>
            <tr>
              <td style={{ "fontWeight": "bold", whiteSpace: "nowrap" }}>Contact Email:</td>
              <td style={{ whiteSpace: "nowrap", position:"relative"}}>{this.props.useCase.contactEmail}</td>
              {this.props.hideDecisions === false?
                this.renderDecision("Data Entitlements Enforced", this.props.useCase.dataEntitlementsEnforced, 
                                    [ "Data entitlements enforced",
                                      "Data entitlements enforcement decision is not recorded",
                                      "Data entitlements not enforced"
                                    ])
              :
                <><td></td><td></td></>
              }
            </tr>
            <tr valign="top">
              <td colSpan={2} style={{ "fontWeight": "bold" }}>Use Case Description:</td>
              {this.props.hideDecisions === false?
                this.renderDecision("Customers To Be Informed", this.props.useCase.informCustomers, 
                                    [ "Customers to be informed",
                                      "Customers informed decision is not recorded",
                                      "Customers need not be informed"
                                    ])
              :
                <><td></td><td></td></>
              }
            </tr>
            <tr>
              <td colSpan={nCols} style={{whiteSpace:"pre-wrap", wordBreak:"break-word"}}>{this.props.useCase.description}</td>
            </tr>
            <tr valign="top">
              <td colSpan={nCols} style={{ "fontWeight": "bold" }}>Data Description:</td>
            </tr>
            <tr>
              <td colSpan={nCols} style={{whiteSpace:"pre-wrap", wordBreak:"break-word"}}>{this.props.useCase.dataDescription}</td>
            </tr>
          </tbody>
        </table>
      );
    } else {
      return (
          <div style={{boxShadow: "var(--one-elevation-box-shadow-8)", padding: "4px 16px", marginBottom:"1em"}}>
              <OwcTypography style={{fontWeight:"bold", color:"Grey"}}>No Use Case</OwcTypography>
          </div>
      );
    }
  }

  /**
   * looks up a ref data item and returns the string with an optional deprecation warning
   * @param {*} refListIdToLookup The id to look up
   * @param {*} alternateValue if not null this will be used for the ref data value
   * @param {*} includeDeprecationWarning if true and the ref list item is not active add a warning
   * @returns the strinbg desctrption of the ref data item, or null if it cannot be found
   */
  lookupRefData(refListIdToLookup, alternateValue = null, includeDeprecationWarning = true) {
    let retVal = alternateValue;
    if (this.state.refdataLoaded) {
      const refDataEntry = this.state.refListsComplete.find(({ refListId }) => refListId === refListIdToLookup);
      if (refDataEntry !== undefined) {
        if (retVal === null) {
          retVal = refDataEntry.description;
        }
        if ((includeDeprecationWarning) && (!refDataEntry.isActive)) {
          retVal += configData.REFDATA_DEPRECATED;
        }
      }
    }
    return (retVal);
  }
  
  voidFn(arg) {
    console.log(arg);
  }

}

UseCaseDetails.defaultProps = {
  useCase: {useCaseId:null,name:null,contactEmail:null,description:null,dataDescription:null,
            dataAccessApproved:null,dataEntitlementsEnforced:null,informCustomers:null},
  refLists: {},
  hideTitle: true,
  hideDecisions: false,
}

export default UseCaseDetails;
