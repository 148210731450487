import React from 'react';
import {configData} from "../config.js";
import UseCaseDetails from "../components/useCase/UseCaseDetails.js";
import ReferenceListSelector from "../components/general/ReferenceListSelector.js";
import SelectedReferenceList from '../components/general/SelectedReferenceList.js';
import '../styles.scss';

import {fetchSigned} from "../shared/Utilities.js"

import {
  OwcButton, OwcTypography, OwcExpandableGroup, OwcExpandable,
   OwcProgressSpinner,
} from '@one/react';

/**
 * The interactive form for mapping a use case to entitlemnts
 *
 * @copyright Roche 2024
 * @author Nick Draper
 */
class MappingUseCase extends React.Component {
  UNSAVED_CHANGES_MESSAGE = "Unsaved changes, click Save Changes to save";
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.state.refDataUseList=[];
    this.state.refProcessingActionList=[];
    if (props.useCaseId !== null)
    {
      this.state.isLoading = true;
    }
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.useCaseId !== this.props.useCaseId) {
      this.loadUseCaseData(this.props.useCaseId, false);
    }
  }

  /**
   * Runs once after construction after everything is initialised
   */
  componentDidMount() {
    // load the reference data and split out the affilate list
    fetchSigned(configData.REFDATA_API_URL + "?includeInactive=true")
      .then(res => res.json())
      .then(
        (result) => {
          const filteredDataUseList = result.filter(value =>
            value.type === "Data Use"
          );
          const filteredProcessingActionList = result.filter(value =>
            value.type === "Processing Action"
          );
          this.setState({
              refDataUseList: filteredDataUseList,
              refProcessingActionList: filteredProcessingActionList
            },
            this.loadUseCaseData(this.props.useCaseId, false)
          );
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {this.setState({error})}
      );
  }

  /**
   * Sets the initial state
   * @returns the initial state dictionary
   */
  initialState() {
    return ({
      isLoading: false,
      useCase:{useCaseId:-1, name:"", contactEmail:null,
        description: null,
        dataDescription: null,
        createdBy: null,
        dateCreated: null,
        dateUpdated: null,
      },
      dataUseList: [],
      processingActionList: [],
      submissionState: null,
      isDataUseExpanded: true,
      isProcessingActionExpanded: true,
      isUseCaseExpanded: true,
    });
  }

  /**
   * Resets the form by setting the state back to the inital state
   */
  handleCancelClick() {
    //reset the state
    this.loadUseCaseData(this.props.useCaseId, true);
    this.props.onUnsavedChangesChange(false);
  }

  /**
   * Validates the form and submits it to the API if valid
   * @returns nothing
   */
  handleSubmitClick() {
    const submissionData = {...this.state.useCase}
    submissionData.dataUseList = [...this.state.dataUseList];
    submissionData.processingActionList =  [...this.state.processingActionList];
    console.log(submissionData)

    this.setState({ submissionState: "Saving ..." });

    const submitForm = () => {
      // decide if it is an update or insert and setup appropriately
      const url = `${configData.USECASE_API_URL}${submissionData.useCaseId}/`;
      const method = 'POST';
      return fetchSigned(url, {
        method: method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submissionData)
      })
    };

    submitForm().then((response) => {
      response.json().then((json) => {
        console.log(`API Response: ${JSON.stringify(json)}`);
        if (response.status === 201) // inserted successfully
        {
          // load the data back from the database to update all fields and ids
          this.loadUseCaseData(json["useCaseId"], false);
          this.props.onUnsavedChangesChange(false);
          this.setState({ submissionState: "Use Case Successfully Saved" });
        } else {
          if (json.errorText.startsWith("ERROR: duplicate key value violates unique constraint")) {
            this.setState({ submissionState: "Error: This use case has already been entered." });
          } else {
            this.setState({ submissionState: `Error saving use case ${json.errorText}` });
          }
        }
      });
    }).catch((error) => {
      this.setState({ submissionState: `Error saving use case ${error}` });
      console.error(`Error when saving use case: ${error}`);
    });

  };

  /**
   * Loads a useCase
   * @param {*} ev the event
   * @param {*} useCaseId  the selected agreeemntId
   */
  loadUseCaseData(useCaseId, clearSubmissionState = true) { 
    this.setState({isLoading:true});
    console.log("Selected use case id: " + useCaseId);

    if (useCaseId !== null) {
      fetchSigned(configData.USECASE_API_URL + useCaseId + "/")
        .then(res => res.json())
        .then(
          (result) => {
            const newState = {useCase: result,
              dataUseList: result.dataUseList,
              processingActionList: result.processingActionList,
              isLoading: false
            }; 
            if (clearSubmissionState) {
              newState.submissionState = null;
            }
            this.setState(newState, this.props.onUnsavedChangesChange(false));
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => { this.setState({error}) }
      );
    } else {
      this.setState(this.initialState(), this.props.onUnsavedChangesChange(false));
    };    
  }

  handleListSelectionChange(selectedList, stateName) {  
    const newState = {submissionState: this.UNSAVED_CHANGES_MESSAGE};
    newState[stateName] = selectedList
    this.setState(newState, this.props.onUnsavedChangesChange(true));
    
  }

  renderRefDataPickerSection(SectionName, sectionTitle, listTitle, stateName, listStateName, disabled) {
    const sectionExpandedStateItem = `is${SectionName}Expanded`;
    const refListData = this.state[listStateName];
    const selectedItems = this.state[stateName];

    return(
      <OwcExpandable key={SectionName} 
            variant="standard" round
            expanded={this.state[sectionExpandedStateItem]}
            onExpandedChange={(ev) => {const newState = {};
                                       newState[sectionExpandedStateItem] = ev.detail;
                                       this.setState(newState)}} >
            <span key={SectionName + "title"} slot="title">
              <OwcTypography variant="title5">
                {`${sectionTitle} - ${this.state[stateName].length===0?"None Selected":`${this.state[stateName].length} Selected`}`}
              </OwcTypography>
            </span>
            <span key={SectionName + "content"} slot="content">
                <ReferenceListSelector size="small"
                  title={listTitle}
                  displayDeprecatedItems="false"
                  displayDeprecatedToggle="false"
                  refListData={refListData}
                  selectedItems={selectedItems}
                  disabled={disabled}
                  onSelectedChange={(idList)=>this.handleListSelectionChange(idList, stateName)}
                />
            </span>
      </OwcExpandable>
    );
  }

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    let messageColour = "black";
    if (this.state.submissionState !== null && this.state.submissionState.startsWith("Err")) {
      messageColour = "red";
    }
    const submissionState = this.state.submissionState;

    const mappingDisabled = (this.state.useCase.dataAccessApproved === false || this.state.useCase.dataEntitlementsEnforced === false)

    if (this.props.useCaseId === null) {
      return (<OwcTypography variant="title6" style={{marginLeft:"0.5em"}}>Save an use case in "Register" first</OwcTypography>);
    } else {
      if (this.state.isLoading){
        return (
          <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
            <OwcProgressSpinner style={{marginTop:'30px'}}/>
          </div>);
      } else {
        return (
          <div>
            <OwcExpandableGroup multiple>
            <OwcExpandable variant="standard" round
                expanded={this.state.isUseCaseExpanded}
                onExpandedChange={(ev) => this.setState({ isUseCaseExpanded: ev.detail })} >
              <span slot="title">
                <OwcTypography variant="title5">
                  {!this.state.isUseCaseExpanded? `Use Case ${this.state.useCase.useCaseId} - ${this.state.useCase.name}` : `Use Case ${this.state.useCase.useCaseId}`}
                </OwcTypography>
              </span>
              <span slot="content">
                  <UseCaseDetails useCase={this.state.useCase}/>
                  <SelectedReferenceList selectedRefDataList={this.state.useCase.purposeList} title="Purpose Classification:"/>
                  {mappingDisabled === true?
                    <OwcTypography variant="title5" style={{marginLeft:"0.25em", color:"orange"}}>Entitlement mapping is disabled as 
                      {this.state.useCase.dataAccessApproved === false?" data access is denied ":" data entitlements are not enforced "}
                      in "Record Decisions"
                    </OwcTypography>
                  :
                  ""}
              </span>
            </OwcExpandable>
            {this.renderRefDataPickerSection("DataUse", "Data Use Mapping", "Data Use", "dataUseList", "refDataUseList", mappingDisabled)}
            {this.renderRefDataPickerSection("ProcessingAction", "Processing Action Mapping", "Processing Action", "processingActionList", "refProcessingActionList", mappingDisabled)}
            </OwcExpandableGroup>
            <div style={{ display:"flex", flexDirection:"column" }}>
              <table width="100%">
                <tbody>
                  <tr>
                    <td style={{verticalAlign:"top"}} align="left">
                      <OwcButton elevated style={{ width: "fit-content" }}
                          onclick={() => this.handleCancelClick()}>
                        Clear Unsaved Changes
                      </OwcButton>
                    </td>

                    <td style={{verticalAlign:"top"}} align="right">
                      <OwcButton style={{ width: "fit-content" }}
                          disabled={((submissionState === null)
                                      || (submissionState === "Saving ...")
                                      || (submissionState.includes("Successfully"))) ? true : false}
                          onclick={() => this.handleSubmitClick()}>
                        {submissionState === "Saving ..." ? submissionState : "Save Changes"}
                      </OwcButton>
                    </td>
                  </tr>
                </tbody>
              </table>
              <OwcTypography variant="title6" style={{ marginBottom: 8, textAlign: "right", color: messageColour }}>
                {submissionState === "Saving ..." ? "" : submissionState}
              </OwcTypography>
            </div>
          </div>
        );
      }
    }
  }
}

export default MappingUseCase;
