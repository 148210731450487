import React from 'react';
import {configData} from "../config.js";
import UseCaseDetails from "../components/useCase/UseCaseDetails.js";
import ReferenceListSelector from "../components/general/ReferenceListSelector.js";
import CountrySelector from "../components/general/CountrySelector.js";
import '../styles.scss';

import {fetchSigned} from "../shared/Utilities.js"

import {
  OwcButton, OwcTypography, OwcExpandableGroup, OwcExpandable,
  OwcInput, OwcProgressSpinner,
  OwcAssistiveText
} from '@one/react';

/**
 * The interactive form for classifying a use case
 *
 * @copyright Roche 2024
 * @author Nick Draper
 */
class ClassifyUseCase extends React.Component {
  UNSAVED_CHANGES_MESSAGE = "Unsaved changes, click Save Changes to save";
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.state.refPurposeList=[];
    this.state.refSystemTypeList=[];
    if (props.useCaseId !== null)
    {
      this.state.isLoading = true;
    }
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.useCaseId !== this.props.useCaseId) {
      this.loadUseCaseData(this.props.useCaseId, false);
    }
  }

  /**
   * Runs once after construction after everything is initialised
   */
  componentDidMount() {
    // load the reference data and split out the affilate list
    fetchSigned(configData.REFDATA_API_URL + "?includeInactive=true")
      .then(res => res.json())
      .then(
        (result) => {
          const filteredPurposeList = result.filter(value =>
            value.type === "Use Case Purpose"
          );
          const filteredInstrumentTypeList = result.filter(value =>
            value.type === "Instrument Type"
          );
          this.setState({
              refPurposeList: filteredPurposeList,
              refSystemTypeList: filteredInstrumentTypeList
            },
            this.loadUseCaseData(this.props.useCaseId, false)
          );
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {this.setState({error})}
      );
  }

  /**
   * Sets the initial state
   * @returns the initial state dictionary
   */
  initialState() {
    return ({
      isLoading: false,
      useCase:{useCaseId:-1, name:"", contactEmail:null,
        description: null,
        dataDescription: null,
        createdBy: null,
        dateCreated: null,
        dateUpdated: null,
      },
      dataNeeds: "",
      purposeList: [],
      systemTypeList: [],
      countryList: [],
      submissionState: null,
      isCountryExpanded: true,
      isPurposeExpanded: true,
      isSystemTypeExpanded: true,
      isUseCaseExpanded: true,
      isDataNeedsExpanded: true,
    });
  }

  /**
   * Resets the form by setting the state back to the inital state
   */
  handleCancelClick() {
    //reset the state
    this.loadUseCaseData(this.props.useCaseId, true);
    this.props.onUnsavedChangesChange(false);
  }

  /**
   * Validates the form and submits it to the API if valid
   * @returns nothing
   */
  handleSubmitClick() {
    const submissionData = {...this.state.useCase}
    submissionData.purposeList = [...this.state.purposeList];
    submissionData.systemTypeList =  [...this.state.systemTypeList];
    submissionData.countryList = [...this.state.countryList];
    console.log(submissionData)

    this.setState({ submissionState: "Saving ..." });

    const submitForm = () => {
      // decide if it is an update or insert and setup appropriately
      const url = `${configData.USECASE_API_URL}${submissionData.useCaseId}/`;
      const method = 'POST';
      return fetchSigned(url, {
        method: method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submissionData)
      })
    };

    submitForm().then((response) => {
      response.json().then((json) => {
        console.log(`API Response: ${JSON.stringify(json)}`);
        if (response.status === 201) // inserted successfully
        {
          // load the data back from the database to update all fields and ids
          this.loadUseCaseData(json["useCaseId"], false);
          this.props.onUnsavedChangesChange(false);
          this.setState({ submissionState: "Use Case Successfully Saved" });
        } else {
          if (json.errorText.startsWith("ERROR: duplicate key value violates unique constraint")) {
            this.setState({ submissionState: "Error: This use case has already been entered." });
          } else {
            this.setState({ submissionState: `Error saving use case ${json.errorText}` });
          }
        }
      });
    }).catch((error) => {
      this.setState({ submissionState: `Error saving use case ${error}` });
      console.error(`Error when saving use case: ${error}`);
    });

  };

  /**
   * Loads a useCase
   * @param {*} ev the event
   * @param {*} useCaseId  the selected agreeemntId
   */
  loadUseCaseData(useCaseId, clearSubmissionState = true) { 
    this.setState({isLoading:true});
    console.log("Selected use case id: " + useCaseId);

    if (useCaseId !== null) {
      fetchSigned(configData.USECASE_API_URL + useCaseId + "/")
        .then(res => res.json())
        .then(
          (result) => {
            const newState = {useCase: result,
              purposeList: result.purposeList,
              systemTypeList: result.systemTypeList,
              countryList: result.countryList,
              isLoading: false
            }; 
            if (clearSubmissionState) {
              newState.submissionState = null;
            }
            this.setState(newState, this.props.onUnsavedChangesChange(false));
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => { this.setState({error}) }
      );
    } else {
      this.setState(this.initialState(), this.props.onUnsavedChangesChange(false));
    };    
  }

  handleListSelectionChange(selectedList, stateName) {  
    const newState = {submissionState: this.UNSAVED_CHANGES_MESSAGE};
    newState[stateName] = selectedList
    this.setState(newState, this.props.onUnsavedChangesChange(true));
    
  }

  handleCountrySelectionChange(selectedCountryCodes) {
    this.setState({
      countryList: selectedCountryCodes,
      submissionState: this.UNSAVED_CHANGES_MESSAGE,
    }, this.props.onUnsavedChangesChange(true));
  }

  renderCountryPickerSection() {
    const countryList = this.state.countryList;
    return (
      <OwcExpandable variant="standard" round
          expanded={this.state.isCountryExpanded}
          onExpandedChange={(ev) => this.setState({ isCountryExpanded: ev.detail })} >
        <span slot="title">
          <OwcTypography variant="title5">
            {`Country Classification - ${countryList.length===0?"None Selected":`${countryList.length} Selected`}`}
          </OwcTypography>
        </span>
        <span slot="content">
            <CountrySelector 
              size="small"
              title="Countries"
              selectedCountryCodes={countryList}
              onSelectedChange={(selectedCountryCodes) => this.handleCountrySelectionChange(selectedCountryCodes)} />
        </span>
      </OwcExpandable>
    );
  }

  renderDataNeedsSection() {
    return (
      <OwcExpandable variant="standard" round
          expanded={this.state.isDataNeedsExpanded}
          onExpandedChange={(ev) => this.setState({ isDataNeedsExpanded: ev.detail })} >
        <span slot="title">
          <OwcTypography variant="title5">
          {
            (this.state.useCase.dataNeeds === null || this.state.useCase.dataNeeds.trim().length === 0)
              ? "Data Needs Classification - Not Entered"
              : "Data Needs Classification - Entered"  
          }
          </OwcTypography>
        </span>
        <span slot="content">
          <OwcInput
              key={"DataNeedsInput"}
              style={{ display: "inline-block"}}
              rows={this.state.useCase.dataNeeds == null ? 4 : Math.max(4, this.state.useCase.dataNeeds.split(/\r\n|\r|\n/).length)}
              cols="100"
              label={"Data Needs"}
              value={this.state.useCase.dataNeeds}
              onValueChange={(ev) => {
                const newUseCase = {...this.state.useCase};
                newUseCase.dataNeeds = ev.detail;
                this.setState({useCase: newUseCase,
                               submissionState: this.UNSAVED_CHANGES_MESSAGE,
                              }, this.props.onUnsavedChangesChange(true));
              }}
              type="textarea"
              no-clear-icon resizable="false">
            <OwcAssistiveText>
              Data products and elements required
            </OwcAssistiveText>
          </OwcInput>
        </span>
      </OwcExpandable>
    );
  }

  renderRefDataPickerSection(SectionName, sectionTitle, listTitle, stateName, listStateName) {
    const sectionExpandedStateItem = `is${SectionName}Expanded`;
    const refListData = this.state[listStateName];
    const selectedItems = this.state[stateName];

    return(
      <OwcExpandable key={SectionName} 
          variant="standard" round
          expanded={this.state[sectionExpandedStateItem]}
          onExpandedChange={(ev) => {
            const newState = {};
            newState[sectionExpandedStateItem] = ev.detail;
            this.setState(newState)
          }}>
        <span key={SectionName + "title"} slot="title">
          <OwcTypography variant="title5">
            {`${sectionTitle} - ${this.state[stateName].length===0?"None Selected":`${this.state[stateName].length} Selected`}`}
          </OwcTypography>
        </span>
        <span key={SectionName + "content"} slot="content">
            <ReferenceListSelector size="small"
              title={listTitle}
              displayDeprecatedItems="false"
              displayDeprecatedToggle="false"
              refListData={refListData}
              selectedItems={selectedItems}
              onSelectedChange={(idList)=>this.handleListSelectionChange(idList, stateName)}
            />
        </span>
      </OwcExpandable>
    );
  }

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    let messageColour = "black";
    if (this.state.submissionState !== null && this.state.submissionState.startsWith("Err")) {
      messageColour = "red";
    }

    const submissionState = this.state.submissionState;

    if (this.props.useCaseId === null) {
      return (<OwcTypography variant="title6" style={{marginLeft:"0.5em"}}>Save an use case in "Register" first</OwcTypography>);
    } else {
      if (this.state.isLoading){
        return (
          <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
            <OwcProgressSpinner style={{marginTop:'30px'}}/>
          </div>);
      } else {
        return (
          <div>
            <OwcExpandableGroup multiple>
            <OwcExpandable variant="standard" round
                expanded={this.state.isUseCaseExpanded}
                onExpandedChange={(ev) => this.setState({ isUseCaseExpanded: ev.detail })} >
              <span slot="title">
                <OwcTypography variant="title5">
                  {!this.state.isUseCaseExpanded? `Use Case ${this.state.useCase.useCaseId} - ${this.state.useCase.name}` : `Use Case ${this.state.useCase.useCaseId}`}
                </OwcTypography>
              </span>
              <span slot="content">
                <UseCaseDetails useCase={this.state.useCase}/>
              </span>
            </OwcExpandable>
            {this.renderRefDataPickerSection("Purpose", "Purpose Classification", "Purpose", "purposeList", "refPurposeList")}
            {this.renderCountryPickerSection()}
            {this.renderRefDataPickerSection("SystemType", "System Type Classification", "Reference System Type", "systemTypeList", "refSystemTypeList")}
            {this.renderDataNeedsSection()}
            </OwcExpandableGroup>
            <div style={{ display:"flex", flexDirection:"column" }}>
              <table width="100%">
                <tbody>
                  <tr>
                    <td style={{verticalAlign:"top"}} align="left">
                      <OwcButton elevated style={{ width: "fit-content" }}
                          onclick={() => this.handleCancelClick()}>
                        Clear Unsaved Changes
                      </OwcButton>
                    </td>

                    <td style={{verticalAlign:"top"}} align="right">
                      <OwcButton style={{ width: "fit-content" }}
                          disabled={((submissionState === null)
                                      || (submissionState === "Saving ...")
                                      || (submissionState.includes("Successfully"))) ? true : false}
                          onclick={() => this.handleSubmitClick()}>
                        {submissionState === "Saving ..." ? submissionState : "Save Changes"}
                      </OwcButton>
                    </td>
                  </tr>
                </tbody>
              </table>
              <OwcTypography variant="title6" style={{ marginBottom: 8, textAlign: "right", color: messageColour }}>
                {submissionState === "Saving ..." ? "" : submissionState}
              </OwcTypography>
            </div>
          </div>
        );
      }
    }
  }
}

export default ClassifyUseCase;
