import React from 'react';
import '../styles.scss';
import {configData} from "../config.js";
import {
  fetchSigned, getDescriptionForRefDataId, getIdForRefData
} from "../shared/Utilities.js"
import DelayedTooltip from "../components/general/DelayedTooltip.js";

import {
  OwcTypography, OwcTable, OwcTableHeader,
  OwcTableCell, OwcTableHeaderCell, OwcTableRow, OwcTableBody,
  OwcButton, OwcProgressSpinner, OwcInput, OwcIconButton
} from '@one/react';

/**
 * The Control for managing user preferences
 *
 * @copyright Roche 2023
 * @author Nick Draper
 */

class UserView extends React.Component {
  UNSAVED_CHANGES_MESSAGE = "Unsaved changes, click Save Changes to save";

  constructor(props) {
    super(props);
    this.state = {
      refListTypes: [],
      structuredReferenceLists: {},
      submissionState: null,
      userPreferences: [],
      inputError: null,
      searchTerm: "",
    }
  }

  /**
   * Runs once when the control is loaded and loads the reference data types
   */
  componentDidMount() {
    // load the reference data types
    fetchSigned(configData.REFDATA_API_URL + "types/")
      .then(res => res.json())
      .then(
          (result) => {
            this.setState({
              refListTypes: result,
            },
              this.loadRefData());
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              error
            });
          }
        );
    this.loadUserPreferences();
  }

  /**
   * Loads the reference data and sorts into seperate lists
   */
  loadRefData() {
    // load the reference data and split into seperate lists
    fetchSigned(configData.REFDATA_API_URL + "?includeInactive=true")
      .then(res => res.json())
      .then(
        (result) => {
          const structuredRefData = {};
          this.state.refListTypes.forEach((typeName) => {
            structuredRefData[typeName] = result.filter(value =>
              value.type === typeName
            );
          });
          this.setState({ structuredReferenceLists: structuredRefData });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )
  }

  /**
   * Runs when the props or state changes
   * @param {*} prevProps The previous propoerties
   * @param {*} prevState The previous state
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.userPreferences !== prevProps.userPreferences) {
      const newData = [...this.state.userPreferences];
      let entry = newData.find(({ userName }) => userName === this.props.userPreferences.userName);
      //get the index
      if (entry !== undefined) {
        for (const [key, value] of Object.entries(this.props.userPreferences)) {
          entry[key] = value;
        }
      } else {
        newData.push({...this.props.userPreferences});
      }
      this.setState({ userPreferences: newData});
    }
  }


  /**
   * Loads the user prefernces for all users
   */
  loadUserPreferences() {
    if (this.props.userName !== null) 
    {
      fetchSigned(configData.USER_API_URL + `preferences`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({userPreferences:result});
        },    
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (errorMsg) => {
          this.setState({
            submissionState: "Error returning reference list" + errorMsg
          });
        }
      );
    }
  }

  /** Hanndles the cancel button click, resetting the form to the current DB ddata */
  handleCancelClick() {
    this.setState({ submissionState: null,
                    }, this.loadUserPreferences());
    this.props.onUnsavedChangesChange(false);
  }

  /**
   * Simple delay function
   * @param {*} milliseconds The number of seconds to wait for
   * @returns A promise that will resolve after the delay
   */
  sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  /**
   * Handles the submit button click saving the changed data to the database
   */
  handleSubmitClick() {
    // introduce a short sleep to allow updates to the comments from the table to update the state
    this.sleep(200).then(r => {
      const submissionData = this.state.userPreferences.filter(value =>
        value.changed === true
      );
      
      this.setState({ submissionState: "Saving ..." });

      const submitForm = () => {
        // decide if it is an update or insert and setup appropriately
        return fetchSigned(configData.USER_API_URL + "preferences/", {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(submissionData)
        })
          .then((response) => {
            console.log(response.status);
            if (response.status === 201) // inserted successfully
            {
              response.json().then((json) => {
                this.setState({ submissionState: "Changes Successfully Saved"}, 
                              this.loadUserPreferences());
                this.props.onUnsavedChangesChange(false);
                console.log("API Response" + JSON.stringify(json));
              }).catch((error) => {
                this.setState({ submissionState: "Error saving changes " + error });
                console.error(error);
              });
            } else {
              response.json().then((json) => {
                console.log("API Response" + JSON.stringify(json));
                this.setState({ submissionState: "Error saving changes " + json.errorText });
              }).catch((error) => {
                this.setState({ submissionState: "Error saving changes " + error })
                console.error(error);
              });
            }
          });
      };
      submitForm();
    })
  }

  /**
   * Handles changes to a select drop down list
   * @param {*} ev The event objecy
   * @param {*} userNameToChange The username for this change
   * @param {*} preferenceName The name of the prefernce to change
   * @param {*} referenceListName The reference list name to use to lookup the id for the text value
   */
  handleSelectChange(ev, userNameToChange, preferenceName, referenceListName) {
    const id = getIdForRefData(ev.target.value, this.state.structuredReferenceLists[referenceListName]);
    if (id !== null || ev.target.value === "All") {
      const newData = [...this.state.userPreferences];
      const entry = newData.find(({ userName }) => userName === userNameToChange);
      //get the index
      if (entry !== undefined) {
        if (id !== entry[preferenceName]) {
          entry[preferenceName] = id;
          entry.changed = true;
          this.setState({ userPrefernces: newData,
            submissionState: this.UNSAVED_CHANGES_MESSAGE,
           }, this.props.onUnsavedChangesChange(true));
        }
      }
    }
  }

  
  /**
   * Renders the ref list table control
   * @returns The JSX of the controls
   */
   renderTable() {
    if (this.state.userPreferences.length === 0 || Object.keys(this.state.structuredReferenceLists).length === 0) {
      return (<OwcProgressSpinner/>);
    } else {
      return (
        <OwcTable key={"userListTable"} style={{ display: "block", minWidth:"50%", maxWidth:"100%" }} size='default' height="auto">
          <OwcTableHeader elevated sticky shrink>
            <OwcTableHeaderCell width={"30%"} resizable id="tooltip-username">Username</OwcTableHeaderCell>
            <DelayedTooltip anchor="tooltip-username" placement="left">The username for this set of user preferences</DelayedTooltip>
            <OwcTableHeaderCell width={"70%"} resizable >Default Affiliate</OwcTableHeaderCell>
          </OwcTableHeader>
          <OwcTableBody>
            {this.state.userPreferences.map((userPreference, index) => this.renderTableRow(userPreference, index))}
          </OwcTableBody>
        </OwcTable>
      );
    }
  }

  /**
   * Renders a row of the user table
   * @param {*} userPreference The user prefernce data for this user
   * @param {*} index The index position within the table
   * @returns The JSX of the controls
   */
  renderTableRow(userPreference, index) {

    let shouldDisplay = true;
    //only render the row if it passes the search criteria
    if (this.state.searchTerm !== undefined && this.state.searchTerm) {
      // search based on type normally, but name for installed base
      let searchedData = userPreference.userName.toUpperCase();
      if (searchedData.indexOf(this.state.searchTerm.toUpperCase()) === -1)
      {
        shouldDisplay = false;
      }
    }

    if (shouldDisplay === false) { 
      return;
    } else {
      return (
        <OwcTableRow key={"row" + userPreference.userName}>
          <OwcTableCell key={"userNameCell" + userPreference.userName}
            style={{wordBreak:"break-word"}} valign="middle"
          >{userPreference.userName}</OwcTableCell>
          {this.renderRefListSelectCell('affiliateRefListId', userPreference, "Affiliate")}
        </OwcTableRow>
      );
    }
  }

  /**
   * Renders a Dropdown select control that uses a reference list for it's options
   * @param {*} preferenceName The name of the preference this relates to
   * @param {*} userPreference The data for the use preference item that defines the current value
   * @param {*} referenceListName The name of the reference list that defines the selection options
   * @returns The JSX of the table cell containing the drop down SELECT
   */
  renderRefListSelectCell(preferenceName, userPreference, referenceListName) {
    const referenceList=this.state.structuredReferenceLists[referenceListName];
    if (referenceList !== undefined) {
      const textValue = getDescriptionForRefDataId(userPreference[preferenceName], referenceList);
      const keyEnding = userPreference.userName + preferenceName;
      return (
        <>
        <OwcTableCell key={"SelectCell" + keyEnding} id={"SelectCell" + keyEnding}
            valign="middle"
        >
          <select  key={"Select" + keyEnding} id={"Select" + keyEnding}
            value={textValue === null? "All": textValue}
            disabled={this.props.userName===userPreference.userName}
            onChange={(ev) => this.handleSelectChange(ev, userPreference.userName, preferenceName, referenceListName)}> 
          <option key={"SelectAll" + keyEnding}>All</option>
          {referenceList.map(item => (
            <option key={"SelectOption" + keyEnding + item.refListId}>
              {item.isActive?item.description:item.description + configData.REFDATA_DEPRECATED}
            </option>
          ))}
          </select>
        </OwcTableCell>
        {this.props.userName===userPreference.userName?
          (
            <DelayedTooltip key={"SelectTooltip" + keyEnding} anchor={"SelectCell" + keyEnding} placement="bottom">
              Disabled as this is you, change your preferences using the user icon in the top right of the screen.
            </DelayedTooltip>
          )
        :
          ""
        }
        </>);
    } else {
      return;
    }
  }

  /**
   * Renders the page
   * @returns The JSX of the controls
   */
  render() {
    let messageColour = "black";
    if (this.state.submissionState !== null && this.state.submissionState.startsWith("Err")) {
      messageColour = "red";
    }

    if (this.state.userPreferences) {
      return (
        <div name="tabContents" className="tabContents" style={{ display:"flex", flexDirection:"column" }}>
          <div style={{display:"flex", flexDirection:"row", flexWrap:"nowrap",
            alignItems:"center", JustifyContent:"space-between",
            alignContent:"space-between", width:"99.5%",
            marginBottom: "0.5em"}}>
            <OwcTypography variant='title6'>User List</OwcTypography>
            <div style={{flexGrow:"3"}}></div>
            <OwcInput
                style={{ width: "15em",  marginLeft:"0.5em"}}
                placeholder="Search..."
                round={false}
                variant="filled"
                value={this.state.searchTerm}
                onValueChange={(ev) => {this.setState({searchTerm:ev.detail})}} >
              <OwcIconButton slot="prefix" icon="search" />
            </OwcInput>
          </div>
          
          {this.renderTable()}
          <br />
          <table width="100%">
          <tbody>
            <tr>
              <td align="left">
                <OwcButton elevated style={{ width: "fit-content" }}
                    onclick={() => this.handleCancelClick()} >
                  Clear Unsaved Changes
                </OwcButton>
              </td>
              <td align="right">
                <OwcButton style={{ width: "fit-content" }}
                  onclick={() => this.handleSubmitClick()}
                  disabled={((this.state.submissionState === "Saving ...") ||
                              (this.state.submissionState === "Changes Successfully Saved") ||
                              (this.state.submissionState === null)) 
                              ? true : false}
                >
                  {this.state.submissionState === "Saving ..." ? this.state.submissionState : "Save Changes"}
                </OwcButton>
              </td>
            </tr>
          </tbody>
        </table>
        <OwcTypography variant="title6" style={{ marginBottom: 8, textAlign: "right", color: messageColour }}>
          {this.state.submissionState === "Saving ..." ? "" : this.state.submissionState}
        </OwcTypography>
        </div>
      );
    }
  }

}

export default UserView;
