/** Config file to hold API endpoints and shared constants across more than one file.
 * 
 * @copyright Roche 2022
 * @author Nick Draper
 */

/** Debug setting for the location of the API root
 * This is not used when built by Gitlab
 *
 * The location for the AWS hosted API for this branch can be extracted from the Gitlab CI/CD Pipeline for this branch
 * https://code.roche.com/dsfairdata/takeoff/-/pipelines
 * Click on the "deploy api's and lambdas" stage link for the most recent job from your branch
 * Towards the bottom of the log there is:
 *      Description         API Stage Endpoint
 *      Value               "https://jd5j6qjuq2.execute-api.eu-central-1.amazonaws.com/api"         <- this is the URL you want
 */
 import './shared/DateFormat.js'

 const LOCAL_API_URL = "https://jd5j6qjuq2.execute-api.eu-central-1.amazonaws.com/api";
 const LOCAL_HOST_REDIRECT = "http://localhost:3000";
 //const LOCAL_API_URL = "http://localhost:3000"; 
 
 const configData = {
    REFDATA_API_URL : (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL : LOCAL_API_URL) + "/ref_data/",
    CONTRACTS_API_URL : (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL: LOCAL_API_URL) + "/contracts/",
    NOTIFICATIONS_API_URL : (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL: LOCAL_API_URL) + "/notifications/",
    TERMPAIRS_API_URL : (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL : LOCAL_API_URL) + "/termpairs/",
    ENTITLEMENTS_API_URL : (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL : LOCAL_API_URL) + "/entitlements/",
    PROPAGATE_API_URL : (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL : LOCAL_API_URL) + "/propagate/",
    PROPAGATED_API_URL : (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL: LOCAL_API_URL) + "/propagated/",
    INST_TYPES_API_URL : (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL: LOCAL_API_URL) + "/inst_type_mapping/",
    ACCOUNT_MAPPING_API_URL : (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL: LOCAL_API_URL) + "/mapping-agreements-to-accounts/",
    DEVICE_MAPPING_API_URL : (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL: LOCAL_API_URL) + "/mapping-agreements-to-devices/",
    MAPPING_VALIDATION_API_URL : (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL: LOCAL_API_URL) + "/mapping-validations/",
    DEVICE_DETAILS_API_URL: (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL: LOCAL_API_URL) + "/devices/",
    USER_API_URL: (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL: LOCAL_API_URL) + "/user/",
    CONTRACT_LINKS_API_URL: (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL: LOCAL_API_URL) + "/contract-links/",
    DATABASE_CHECK_API_URL: (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL: LOCAL_API_URL) + "/check_database/",
    DATABASE_START_API_URL: (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL: LOCAL_API_URL) + "/start_database/",
    USECASE_API_URL: (process.env.REACT_APP_DSFAIR_API_URL ? process.env.REACT_APP_DSFAIR_API_URL: LOCAL_API_URL) + "/usecase/",

    DEV_OR_PROD: ( process.env.REACT_APP_DEV_OR_PROD ? process.env.REACT_APP_DEV_OR_PROD : "local development" ),
    BRANCH_NAME: ( process.env.REACT_APP_BRANCH ? process.env.REACT_APP_BRANCH : "unknown branch" ),
    VERSION_DATE: ( process.env.REACT_APP_COMMIT_TIMESTAMP ? process.env.REACT_APP_COMMIT_TIMESTAMP : (new Date()).format("yyyy-mm-dd'T'HH:MM:ss") ),
    VERSION_BUILD_NUMBER: ( process.env.REACT_APP_PIPELINE_IID ? process.env.REACT_APP_PIPELINE_IID : 0 ),
    VERSION_BUILD_URL: ( process.env.REACT_APP_PIPELINE_URL ? process.env.REACT_APP_PIPELINE_URL : "" ),

    // These string MUST match those returned by the authorization 
    ENV_ROLE_SUFFIX : ( process.env.REACT_APP_DEV_OR_PROD === "production" ||
                        process.env.REACT_APP_DEV_OR_PROD === "release-candidate" ?
                        "_prod" : "_dev" ),
    ROLE_STEWARD : "steward",
    ROLE_AFFILIATE : "affiliate",
    ROLE_VIEWER : "viewer",
    ROLE_CONSUMER : "consumer",
    ROLE_CONNECTED_DEVICES : "connected_devices",

    ENTITLEMENT_DATA_USE: "Data Use",
    ENTITLEMENT_PROCESSING_ACTION: "Processing Action",
    REFDATA_DEPRECATED: " (Deprecated)",

    TAB_EXTRACT: "Capture Agreement",
    TAB_TRANSLATE: "Translate & Structure",
    TAB_HARMONIZE: "Harmonize",
    TAB_OVERVIEW: "Review",
    TAB_OVERVIEW_FOR_STEWARD: "Review & Publish",
    TAB_MAP_ACCOUNTS: "Mapped Accounts",
    TAB_MAP_DEVICES: "Mapped Devices",
    TAB_INST_TYPES: "Instrument Types",
    TAB_INST_TYPES_CONNECTED_DEVICES: "Instrument Types Connected Device Mapping",
    TAB_INST_TYPES_INSTALLED_BASE: "Instrument Types Installed Base Mapping",
    TAB_REFERENCE_LISTS: "Reference Lists",
    TAB_DEVICE_SEARCH: "Device Search",
    TAB_USER_VIEW: "User View",
    TAB_VERIFY_DEVICE_MAPPING: "Verify Mappings",
    TAB_AGREEMENTS: "Agreements",
    TAB_USE_CASES: "Use Cases",
    TAB_REGISTER_USE_CASES: "Register",
    TAB_CLASSIFY_USE_CASES: "Classify",
    TAB_RECORD_DECISIONS_USE_CASES: "Record Decisions",
    TAB_MAPPING_USE_CASES: "Entitlement Mapping",
    TAB_PUBLISH_USE_CASES: "Verify & Publish",
    TAB_DATA_PRODUCTS: "Data Products",


    COLOUR_GREEN: "HoneyDew",
    COLOUR_BLUE: "AliceBlue",
    COLOUR_YELLOW: "Ivory",
    COLOUR_EXCLUDED: "LightPink",
    COLOUR_WHITE: "White",
    COLOUR_SELECTED: "#E0ECF9",

    SHORT_SOURCE_NAME_DICT: {
      "Cube connected device": "Cube",
      "Installed Base": "IB",
      "serial_no curation": "Curation",
    },
 
    COGNITO_CONFIG: {
        Auth: {
            region: "eu-central-1",
            userPoolId: "eu-central-1_QzBB4tiCu",
            identityPoolId: (process.env.REACT_APP_ID_POOL_ID ? process.env.REACT_APP_ID_POOL_ID : "eu-central-1:c628ccfd-8396-4a53-b1ce-971186a41901"),
            identityPoolRegion: "eu-central-1",
            userPoolWebClientId: (process.env.REACT_APP_WEBCLIENT_ID ? process.env.REACT_APP_WEBCLIENT_ID :"1qrq194phpbt9f0cri0n3m7agq"),
            oauth: {
              domain: "dataentitlements.auth.eu-central-1.amazoncognito.com",
              scope: ["phone", "email", "openid"],
              redirectSignIn: (process.env.REACT_APP_DSFAIR_WEBSITE_URL ? process.env.REACT_APP_DSFAIR_WEBSITE_URL : LOCAL_HOST_REDIRECT),
              redirectSignOut: (process.env.REACT_APP_DSFAIR_WEBSITE_URL ? process.env.REACT_APP_DSFAIR_WEBSITE_URL : LOCAL_HOST_REDIRECT),
              responseType: "code"
            }
          }
    },

    AWS_REGION: "eu-central-1",
    AWS_SERVICE: "execute-api"
 }
 
 export {configData}
 
