import React from 'react';
import {configData} from "../config.js";
import '../styles.scss';

import {formatDate, fetchSigned, convertToDate, truncateDate} from "../shared/Utilities.js"

import {
  OwcButton, OwcTypography, OwcExpandableGroup, OwcExpandable,
  OwcDatepicker, OwcInput, OwcProgressSpinner, OwcCheckbox,
  OwcAssistiveText
} from '@one/react';

/**
 * The interactive form for registering a use case
 *
 * @copyright Roche 2024
 * @author Nick Draper
 */
class RegisterUseCase extends React.Component {
  UNSAVED_CHANGES_MESSAGE = "Unsaved changes, click Save Changes to save";
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = this.initialState();
    if (props.useCaseId !== null)
    {
      this.state.isLoading = true;
    }
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.useCaseId !== this.props.useCaseId) {
      this.loadUseCaseData(this.props.useCaseId, false);
    }
  }

  /**
   * Runs once after construction after everything is initialised
   */
  componentDidMount() {
    this.loadUseCaseData(this.props.useCaseId, false);
  }

  /**
   * Sets the initial state
   * @returns the initial state dictionary
   */
  initialState() {
    return ({
      isLoading: false,
      useCase:{useCaseId:-1, name:"", contactEmail:"",
        description: "",
        dataDescription: "",
        createdBy: this.props.userName,
        dateCreated: null,
        dateUpdated: null,
        validFrom: null,
        validTo: null,
        versionNo: null,
      },
      isControlExpanded: true,
      submissionState: null,
      deleteEnabled: false,
    })
  }

  /**
   * Resets the form by setting the state back to the inital state
   */
  handleCancelClick() {
    //reset the state
    this.loadUseCaseData(this.props.useCaseId);
    this.props.onUnsavedChangesChange(false);
  }

  handleDeleteClick() {
    const submitForm = () => {
      return fetchSigned(configData.USECASE_API_URL + this.state.useCase.useCaseId + "/", {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          console.log(response.status);
          if (response.status === 204) // done successfully
          {
            const newState = this.initialState();
            // reset the form
            newState.submissionState = "Successfully Deleted";
            this.setState(newState);
            this.props.onUnsavedChangesChange(false);
            this.props.onUseCaseListChanged(null);
          } else {
            response.json().then((json) => {
              console.log("API Response" + JSON.stringify(json));
              this.setState({ submissionState: "Error deleting " + json });
            }).catch((error) => {
              console.error(error);
              this.setState({ submissionState: "Error deleting " + error });
            });
          }

        });
    };
    submitForm();
  }

  /**
   * checks the date validation for the validFrom and validTo fields
   * @returns true if the validation warning should be displayed
   */
   checkDateWarning() {
    // dateTo
    if ((this.state.useCase.validTo !== null) && (this.state.useCase.validTo.length !== 0)) {
      if ((this.state.useCase.validFrom !== null) && (this.state.useCase.validFrom.length !== 0)) {
        if (this.state.useCase.validTo < this.state.useCase.validFrom) {
          return true;
        }
      }
    }
    return false;
  }


  /**
   * Validates the form and submits it to the API if valid
   * @returns nothing
   */
  handleSubmitClick() {
    const submissionData = {...this.state.useCase};
    // for all the date fields truncate the time section off and convert to UTC to prevent issues with time zones.
    const dateFields = ["validFrom", "validTo"];
    dateFields.forEach((fieldName) => {
      const date = submissionData[fieldName];
      if (date !== null && date !== undefined){
        submissionData[fieldName] = truncateDate(date);
      }
    });
    console.log(submissionData)

    this.setState({ submissionState: "Saving ..." });

    const submitForm = () => {
      // decide if it is an update or insert and setup appropriately
      const url = `${configData.USECASE_API_URL}${submissionData.useCaseId}/`;
      const method = 'POST';
      return fetchSigned(url, {
        method: method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submissionData)
      })
    };

    submitForm().then((response) => {
      response.json().then((json) => {
        console.log(`API Response: ${JSON.stringify(json)}`);
        if (response.status === 201) // inserted successfully
        {
          // load the data back from the database to update all fields and ids
          this.loadUseCaseData(json["useCaseId"], false);
          this.props.onUseCaseListChanged(json["useCaseId"]);
          this.props.onUnsavedChangesChange(false);
          this.setState({ submissionState: "Use Case Successfully Saved" });
        } else {
          if (json.errorText.startsWith("ERROR: duplicate key value violates unique constraint")) {
            this.setState({ submissionState: "Error: This use csae has already been entered." });
          } else {
            this.setState({ submissionState: `Error saving use case ${json.errorText}` });
          }
        }
      });
    }).catch((error) => {
      this.setState({ submissionState: `Error saving use case ${error}` });
      console.error(`Error when saving use case: ${error}`);
    });
  }

  /**
   * Loads a useCase
   * @param {*} ev the event
   * @param {*} useCaseId  the selected agreeemntId
   */
  loadUseCaseData(useCaseId, clearSubmissionState = true) {
    this.setState({isLoading:true});
    console.log("Selected usecase id: " + useCaseId);

    if (useCaseId !== null) {
      fetchSigned(configData.USECASE_API_URL + useCaseId + "/")
        .then(res => res.json())
        .then(
          (result) => {
            if (result.validFrom !== null) {
              result.validFrom = convertToDate(result.validFrom);
            };
            if (result.validTo !== null) {
              result.validTo = convertToDate(result.validTo);
            };
            const newState = {useCase: result,
                              isLoading:false,
                              isControlExpanded: true};
            if (clearSubmissionState) {
              newState.submissionState = null;
            }
            this.setState(
              newState,
              this.props.onUnsavedChangesChange(false));
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => { this.setState({error}) }
      );
    } else {
      this.setState(this.initialState(), this.props.onUnsavedChangesChange(false));
    };    
  }

  isEmailAddressValid(email) {
    if (this.state.useCase.contactEmail !== null && this.state.useCase.contactEmail.trim().length !== 0) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email)
    }
    return true;
  }

  handleExpandedChange(expanded) {
    this.setState({isControlExpanded: expanded});
  }



  handleDateChange(newDate, fieldName) {
    const result = {...this.state.useCase};
    if ((newDate === null) && (this.state.useCase[fieldName] !== null))
    {
      result[fieldName] = null;
      this.setState({useCase: result,
                      submissionState: this.UNSAVED_CHANGES_MESSAGE
      });
      this.props.onUnsavedChangesChange(true);
    } else {
      const newDateParsed = convertToDate(newDate);
      if (newDateParsed !== result[fieldName]) {
        result[fieldName] = newDateParsed;
        //save the change
        this.setState({useCase: result,
                        submissionState: this.UNSAVED_CHANGES_MESSAGE
        });
        this.props.onUnsavedChangesChange(true);
      }
    }
  }
  

  renderUseCaseSection() {
    const exampleMinDate = new Date();
    exampleMinDate.setFullYear(exampleMinDate.getFullYear() - 10);
    const exampleMaxDate = new Date();
    exampleMaxDate.setFullYear(exampleMaxDate.getFullYear() + 10);

    const nameError = (this.state.useCase.name === null || this.state.useCase.name.trim().length === 0);
    const contactEmailWarning = (this.state.useCase.contactEmail !== null && this.isEmailAddressValid(this.state.useCase.contactEmail.trim()));
    const descriptionWarning = (this.state.useCase.description === null  || this.state.useCase.description.trim().length === 0);
    const displayUseCaseId = (this.state.useCase.useCaseId === null ||  this.state.useCase.useCaseId <= 0)? "":`${this.state.useCase.useCaseId}`;

    return(
      <OwcExpandable variant="filled" roundedControl expanded={this.state.isControlExpanded}
          onExpandedChange={(ev) => this.handleExpandedChange(ev.detail)}>
        <span slot="title">
          <OwcTypography variant="title5">
            {!this.state.isControlExpanded? `Use Case ${displayUseCaseId} - ${this.state.useCase.name}` : `Use Case ${displayUseCaseId}`}
          </OwcTypography>
        </span>
        <span slot="content">
            <OwcInput key={"Name"} style={{ width: "100%" }}
              label="Name" value={this.state.useCase.name}
              onValueChange={(ev) => { 
                if (ev.detail !== this.state.useCase.name) {
                  const newUseCase= {...this.state.useCase};
                  newUseCase.name = ev.detail;
                  this.setState({useCase: newUseCase, submissionState: this.UNSAVED_CHANGES_MESSAGE}, this.props.onUnsavedChangesChange(true));
                }
              }}
              validity={{state: nameError? "error" : "valid"}}
              no-clear-icon>
            <OwcAssistiveText>
              {nameError?
                "Required" :
                "The unique name of this use case"
              }
            </OwcAssistiveText>
          </OwcInput>
          <br />

          <OwcInput key={"ContactEmail"} style={{ width: "100%" }}
              label="Contact Email" value={this.state.useCase.contactEmail}
              onValueChange={(ev) => {
                if (ev.detail !== this.state.useCase.contactEmail) {
                  const newUseCase= {...this.state.useCase};                            
                  newUseCase.contactEmail = ev.detail;
                  this.setState({useCase: newUseCase, submissionState: this.UNSAVED_CHANGES_MESSAGE}, this.props.onUnsavedChangesChange(true));
                }
              }}
              validity={{
                state: contactEmailWarning? "valid": "warning"
              }}
              no-clear-icon>
            <OwcAssistiveText style={contactEmailWarning?{}:{color:"orange"}}>
              {contactEmailWarning
                ?(this.state.useCase.contactEmail === null || this.state.useCase.contactEmail.trim().length === 0)
                  ? "The contact email should be provided if available"
                  : "The contact email for this use case"
                : "Please ensure the email address is valid"
              }
            </OwcAssistiveText>
          </OwcInput>
          <br />

          <OwcInput
              key={"useCaseDescription"}
              style={{ display: "inline-block"}}
              rows={this.state.useCase.description == null ? 4 : Math.max(4, this.state.useCase.description.split(/\r\n|\r|\n/).length)}
              cols="100"
              label={"Use Case Description"}
              value={this.state.useCase.description}
              onValueChange={(ev) => {
                if (ev.detail !== this.state.useCase.description) {
                  const newUseCase= {...this.state.useCase};                            
                  newUseCase.description = ev.detail;
                  this.setState({useCase: newUseCase, submissionState: this.UNSAVED_CHANGES_MESSAGE}, this.props.onUnsavedChangesChange(true));
                }
              }}
              type="textarea"
              no-clear-icon resizable="false"
              validity={{state: descriptionWarning? "warning" : "valid"}}
              >
            <OwcAssistiveText style={descriptionWarning?{color:"orange"}:{}}>
              {descriptionWarning?
                "The description of the use case should be provided if available" :
                "Provide information of the intended use of the data, including business justification and expected outcomes"
              }
            </OwcAssistiveText>
          </OwcInput>
          <br />
          <br />

          <OwcInput
              key={"useCaseDataDescription"}
              style={{ display: "inline-block"}}
              rows={this.state.useCase.dataDescription == null ? 4 : Math.max(4, this.state.useCase.dataDescription.split(/\r\n|\r|\n/).length)}
              cols="100"
              label={"Data Need Description"}
              value={this.state.useCase.dataDescription}
              onValueChange={(ev) => {
                if (ev.detail !== this.state.useCase.dataDescription) {
                  const newUseCase= {...this.state.useCase};                            
                  newUseCase.dataDescription = ev.detail;
                  this.setState({useCase: newUseCase, submissionState: this.UNSAVED_CHANGES_MESSAGE}, this.props.onUnsavedChangesChange(true));
                }
              }}
              type="textarea"
              no-clear-icon resizable="false">
            <OwcAssistiveText>
              Description of the data elements needed with reference to specific data products (when available), product types and countries
            </OwcAssistiveText>
          </OwcInput>
          <br />
          <br />

          <OwcTypography variant="caption">Use Case Validity</OwcTypography>
          <div style={{display:'flex', flexDirection:'row'}}>
            <div>
              <OwcDatepicker key="dateFrom" autoClose label="From"
                value={this.state.useCase.validFrom} format={"dd-MMM-yyyy"}
                onValueChange={(ev) => this.handleDateChange(ev.detail, "validFrom")}
              >
                <span slot="assistive-text">
                  {this.checkDateWarning()? "" : `e.g. ${formatDate(exampleMinDate)}`}
                </span>
              </OwcDatepicker>
              {this.checkDateWarning()
                ?<OwcTypography style={{"color":"orange"}}variant="caption">To date is less than From date</OwcTypography>
                :""
              }
            </div>
            <div style={{marginLeft:"1em"}}>
              <OwcDatepicker  key="dateTo" autoClose label="To"
                value={this.state.useCase.validTo} format={"dd-MMM-yyyy"}
                onValueChange={(ev) => this.handleDateChange(ev.detail, "validTo")}
              >
                <span slot="assistive-text">e.g. {formatDate(exampleMaxDate)}</span>
              </OwcDatepicker>
            </div>
          </div>
        </span>
      </OwcExpandable>
    );
  }

  /**
   * Renders the delete button if in edit mode
   * @returns The JSX of the controls
   */
  renderDeleteOption() {
    if (this.props.useCaseId !== null) {
      let warningText = "";
      if (this.state.useCase.versionNo !== null){
        warningText = "this use case is published";
      }
      return (
        <td style={{verticalAlign:"top"}} align="centre">
          <OwcButton style={{ width: "fit-content", marginBottom:"0.65em"}}
            key="DeleteButton"
            onclick={() => this.handleDeleteClick()} disabled={this.state.deleteEnabled ? false : true}
          >
            Delete Use Case
          </OwcButton>
          <OwcCheckbox
            key="DeleteButtonCheckbox"
            checked={this.state.deleteEnabled}
            onValueChange={(ev) => this.setState({deleteEnabled: !this.state.deleteEnabled})}>
              <OwcTypography variant="caption">Enable Deletion</OwcTypography>
            </OwcCheckbox> 
            {warningText !== ""?
              <OwcTypography variant="caption" style={{color:"orange"}}>Warning: {warningText}</OwcTypography>
              : ""
            }
        </td>
      );
    }
  }

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    let messageColour = "black";
    if (this.state.submissionState !== null && this.state.submissionState.startsWith("Err")) {
      messageColour = "red";
    }

    const submissionState = this.state.submissionState;
    const nameError = (this.state.useCase.name === null || this.state.useCase.name.trim().length === 0);

    if (this.state.isLoading){
      return (
        <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
          <OwcProgressSpinner style={{marginTop:'30px'}}/>
        </div>);
    } else {
      return (
        <div className="ExtractAndCaptureAgreement">
          <OwcExpandableGroup multiple>
          {this.renderUseCaseSection()}
          </OwcExpandableGroup>
          <div style={{ display:"flex", flexDirection:"column" }}>
            <table width="100%">
              <tbody>
                <tr>
                  <td style={{verticalAlign:"top"}} align="left">
                    <OwcButton elevated style={{ width: "fit-content" }}
                        onclick={() => this.handleCancelClick()}>
                      Clear Unsaved Changes
                    </OwcButton>
                  </td>
                  {this.renderDeleteOption()}
                  <td style={{verticalAlign:"top"}} align="right">
                    <OwcButton style={{ width: "fit-content" }}
                        disabled={((submissionState === null)
                                    || (submissionState === "Saving ...")
                                    || (submissionState.includes("Successfully"))
                                    || (nameError)) ? true : false}
                        onclick={() => this.handleSubmitClick()}>
                      {submissionState === "Saving ..." ? submissionState : "Save Changes"}
                    </OwcButton>
                  </td>
                </tr>
              </tbody>
            </table>
            <OwcTypography variant="title6" style={{ marginBottom: 8, textAlign: "right", color: messageColour }}>
              {nameError? "Please complete the required fields to enable saving" :
                  submissionState === "Saving ..." ? "" : submissionState}
            </OwcTypography>
          </div>
        </div>
      );
    }
  }
}

export default RegisterUseCase;
