import { OwcListItem, OwcCheckbox, OwcList, OwcTypography } from '@one/react';
import React from 'react';
import '../../styles.scss';

/**
 * Renders a reference list as as selection list.
 *
 * @copyright Roche 2024
 * @author Geoffroy Maillol
 */
class ReferenceListSelector extends React.Component {
    /**
     * Constructor 
     * 
     * @param props The properties passed
     */
    constructor(props) {
        super(props);
        this.state = {
          showDeprecated: props.displayDeprecatedItems,
        };
    }

    handleShowDeprecatedValueChange(showDeprecated) {
      this.setState({
        showDeprecated: showDeprecated
      });
    }

    renderItem(item) {
      if (item.isActive || (!item.isActive && this.state.showDeprecated)) {
        return (
          <OwcListItem key={item.refListId} id={item.refListId} 
              disabled={this.props.disabled}
              size={this.props.size}
              value={item.refListId} >
            {item.description}{item.isActive? "" : " (Deprecated)"}
          </OwcListItem>
        );
      }
    }

    /**
     * Renders the controls
     * @returns The JSX of the controls
     */
    render() {
      if (this.props.refListData) {
        return (
          <div style={{ display:"flex", flexDirection:"column" }}>
            {this.props.title.length>0?
            <OwcTypography variant="title6">
              {this.props.title}
            </OwcTypography>
            :
            ""
            }
            {
              this.props.displayDeprecatedToggle === false
              ? <OwcCheckbox disabled={this.props.disabled} onValueChange={(ev) => this.handleShowDeprecatedValueChange(ev.detail)}>
                  Display deprecated items
                </OwcCheckbox>
              : ""
            }
            
            <OwcList multiple showSelectorControl selected={this.props.selectedItems}
                onSelectedChange={(ev) => this.props.onSelectedChange(ev.detail)}>
              {this.props.refListData.map(item => this.renderItem(item))}
            </OwcList>
          </div>
        );
      }
    }
}

ReferenceListSelector.defaultProps = {
  size: 'small',
  displayDeprecatedToggle: false,
  displayDeprecatedItems: false,
  title: "",
  disabled: false,
}

export default ReferenceListSelector
