import { OwcIcon } from '@one/react';
import DelayedTooltip from '../general/DelayedTooltip';
import React from 'react';
import '../../styles.scss';

/**
 * Renders an icon to display device moves
 *
 * @copyright Roche 2024
 * @author Nick Draper
 */
class UnpublishedEditsIcon extends React.Component {
    /**
     * Constructor 
     * 
     * @param props The properties passed
     */
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Renders the controls
     * @returns The JSX of the controls
     */
    render() {
      const keyEnding = this.props.id
      return (
        <>
          <OwcIcon id={"UnpublishedEditsIcon" + keyEnding} style={{fontSize:this.props.iconSize}}>
          <svg width="800px" height="800px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
              <g fillRule="evenodd" clipRule="evenodd" stroke="none" strokeWidth="1">
                  <path d="M1468.235 113v99.388l-112.94 112.941v-99.388H112.94v1468.235h1242.353v-438.211l112.941-112.941v664.094H0V113h1468.235ZM421.271 1163.353c96-9.035 154.729 108.423 190.87 197.647 28.235 68.894 38.4 92.612 72.283 96 33.882 3.388 89.223-58.73 112.94-101.647 16.53-26.51 51.42-34.6 77.93-18.07 26.51 16.529 34.6 51.42 18.07 77.929-9.035 16.94-92.611 160.376-205.552 160.376h-9.036c-70.023-4.517-121.976-48.564-169.411-166.023-47.436-117.46-77.93-127.624-77.93-127.624a484.518 484.518 0 0 0-97.13 225.883c-6.549 31.187-37.14 51.16-68.329 44.611-31.187-6.55-51.16-37.141-44.611-68.33 20.33-94.87 79.059-310.587 199.906-320.752Zm256.376-485.647v112.941H338.824V677.706h338.823ZM903.53 451.824v112.94H338.824v-112.94h564.705Z"/>
                  <path d="m1903.059 468.765-225.883-225.883a56.47 56.47 0 0 0-80.188 0L919.341 920.53a56.476 56.476 0 0 0-15.813 39.53v282.353h282.354a56.47 56.47 0 0 0 39.53-16.941l677.647-677.647c21.523-21.959 21.523-57.101 0-79.06Zm-740.894 660.706H1016.47V983.776l451.764-451.764 145.694 145.694-451.764 451.765Zm531.953-531.953-145.694-145.694 89.223-89.224 145.694 145.694-89.223 89.224Z"/>
              </g>
          </svg>
          </OwcIcon>
          {this.props.iconTooltip
            ?<DelayedTooltip key={"UnpublishedEditsIconToolTip" + keyEnding} anchor={"UnpublishedEditsIcon" + keyEnding}>{this.props.iconTooltip}</DelayedTooltip>
            : ""
          }
        </>
      );
    }
}

UnpublishedEditsIcon.defaultProps = {
  id: Math.random(),
  iconTooltip: "Unpublished Edits",
  iconFlat: "true",
}

export default UnpublishedEditsIcon
