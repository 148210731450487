import React from 'react';
import {configData} from "../config.js";
import UseCaseDetails from "../components/useCase/UseCaseDetails.js";
import SelectedReferenceList from '../components/general/SelectedReferenceList.js';
import '../styles.scss';

import {fetchSigned} from "../shared/Utilities.js"

import {
  OwcButton, OwcTypography, OwcExpandableGroup, OwcExpandable,
  OwcInput, OwcProgressSpinner, OwcIcon,
  OwcAssistiveText,
  OwcToggleButtonGroup, OwcToggleButton
} from '@one/react';

/**
 * The interactive form for recording governingt body decisions on a use case
 *
 * @copyright Roche 2024
 * @author Nick Draper
 */
class RecordDecisionUseCase extends React.Component {
  UNSAVED_CHANGES_MESSAGE = "Unsaved changes, click Save Changes to save";
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = this.initialState();
    if (props.useCaseId !== null)
    {
      this.state.isLoading = true;
    }
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.useCaseId !== this.props.useCaseId) {
      this.loadUseCaseData(this.props.useCaseId, false);
    }
  }

  /**
   * Runs once after construction after everything is initialised
   */
  componentDidMount() {
    this.loadUseCaseData(this.props.useCaseId, false);
  }

  /**
   * Sets the initial state
   * @returns the initial state dictionary
   */
  initialState() {
    return ({
      isLoading: false,
      useCase:{useCaseId:-1, name:"", contactEmail:null,
        description: null,
        dataDescription: null,
        createdBy: null,
        dateCreated: null,
        dateUpdated: null,
        decisionNotes: null,
        purposeList:[],
      },
      isUseCaseExpanded:true,
      isDecisionRecordExpanded: true,
      submissionState: null,
    })
  }

  /**
   * Resets the form by setting the state back to the inital state
   */
  handleCancelClick() {
    //reset the state
    this.loadUseCaseData(this.props.useCaseId);
    this.props.onUnsavedChangesChange(false);
  }


  /**
   * Validates the form and submits it to the API if valid
   * @returns nothing
   */
  handleSubmitClick() {
    const submissionData = this.state.useCase;
    console.log(submissionData)

    this.setState({ submissionState: "Saving ..." });

    const submitForm = () => {
      // decide if it is an update or insert and setup appropriately
      const url = `${configData.USECASE_API_URL}${submissionData.useCaseId}/`;
      const method = 'POST';
      return fetchSigned(url, {
        method: method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submissionData)
      })
    };

    submitForm().then((response) => {
      response.json().then((json) => {
        console.log(`API Response: ${JSON.stringify(json)}`);
        if (response.status === 201) // inserted successfully
        {
          // load the data back from the database to update all fields and ids
          this.loadUseCaseData(json["useCaseId"], false);
          this.props.onUnsavedChangesChange(false);
          this.setState({ submissionState: "Use Case Successfully Saved" });
        } else {
          if (json.errorText.startsWith("ERROR: duplicate key value violates unique constraint")) {
            this.setState({ submissionState: "Error: This use csae has already been entered." });
          } else {
            this.setState({ submissionState: `Error saving use case ${json.errorText}` });
          }
        }
      });
    }).catch((error) => {
      this.setState({ submissionState: `Error saving use case ${error}` });
      console.error(`Error when saving use case: ${error}`);
    });
  };

  /**
   * Loads a useCase
   * @param {*} ev the event
   * @param {*} useCaseId  the selected agreeemntId
   */
  loadUseCaseData(useCaseId, clearSubmissionState = true) {
    this.setState({isLoading:true});
    console.log("Selected usecase id: " + useCaseId);

    if (useCaseId !== null) {
      fetchSigned(configData.USECASE_API_URL + useCaseId + "/")
        .then(res => res.json())
        .then(
          (result) => {
            const newState = {useCase: result,
                              isLoading:false};
            if (clearSubmissionState) {
              newState.submissionState = null;
            }
            this.setState(
              newState,
              this.props.onUnsavedChangesChange(false));
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => { this.setState({error}) }
      );
    } else {
      this.setState(this.initialState(), this.props.onUnsavedChangesChange(false));
    };    
  }   

  handleToggleChange(e, dataField) {
    const newItem = {...this.state.useCase};
    const detail = e.detail[0];
    const val =  detail===1?true:detail===2?null:false;
    if (this.state.useCase[dataField] !== val) {
      newItem[dataField] = val;
      this.setState({useCase: newItem,
        submissionState: this.UNSAVED_CHANGES_MESSAGE,
      }, this.props.onUnsavedChangesChange(true));
    }
  }

  renderDecisionRecord() {
    return(
      <table>
        <tbody>
          {this.renderDecisionRow("Data access approved", "dataAccessApproved")}
          {this.renderDecisionRow("Data entitlements enforced", "dataEntitlementsEnforced", this.state.useCase.dataAccessApproved === false)}
          {this.renderDecisionRow("Customers to be informed", "informCustomers", this.state.useCase.dataAccessApproved === false)}
        </tbody>
      </table>
    );
  }

  renderDecisionRow(title, datafield, isDisabled=false) {
    return(
      <tr>
        <td><OwcTypography>{title}</OwcTypography></td>
        <td>{this.renderToggleOption(datafield, isDisabled)}</td>
      </tr>
    );
  }


  
  renderToggleOption(dataField, isDisabled=false) {
    const item = this.state.useCase;
    const selectedValue = item[dataField] === null? 2: item[dataField] === true? 1: 3;

    return (
      <>
      <OwcToggleButtonGroup exclusive="true" rounded
        disabled={isDisabled}
        value={[selectedValue]}
        onValueChange={(e)=>this.handleToggleChange(e,dataField)}
        style={isDisabled===true?{opacity:"0.75"}:{}}
      >
        <OwcToggleButton  value={1} style={{color:"red"}}>
          <OwcIcon key={"iconStatusTrue" + dataField} 
            name="circle_confirm" family="filled"
            style={{fontSize:"1.5em", color: "var(--one-color-green-500)"}} 
            />
        </OwcToggleButton>
        <OwcToggleButton  value={2} >
          <OwcIcon key={"iconStatusNull" + dataField} 
            name="circle_help" family="filled"
            style={{fontSize:"1.5em", color: "var(--one-color-orange-600)"}} 
            />
        </OwcToggleButton>
        <OwcToggleButton  value={3} >
          <OwcIcon key={"iconStatusFalse" + dataField} 
            name="circle_clear" family="filled"
            style={{fontSize:"1.5em", color: "var(--one-color-red-500)"}} 
            />
        </OwcToggleButton>
      </OwcToggleButtonGroup>
      </>
    );
  }

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    let messageColour = "black";
    if (this.state.submissionState !== null && this.state.submissionState !== undefined && this.state.submissionState.startsWith("Err")) {
      messageColour = "red";
    }

    const submissionState = this.state.submissionState;
    if (this.props.useCaseId === null) {
      return (<OwcTypography variant="title6" style={{marginLeft:"0.5em"}}>Save an use case in "Register" first</OwcTypography>);
    } else {
      if (this.state.isLoading){
        return (
          <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
            <OwcProgressSpinner style={{marginTop:'30px'}}/>
          </div>);
      } else {
        return (
          <div className="ExtractAndCaptureAgreement">
            <OwcExpandableGroup multiple>
            <OwcExpandable variant="standard" round
                expanded={this.state.isUseCaseExpanded}
                onExpandedChange={(ev) => this.setState({ isUseCaseExpanded: ev.detail })} >
              <span slot="title">
                <OwcTypography variant="title5">
                  {!this.state.isUseCaseExpanded? `Use Case ${this.state.useCase.useCaseId} - ${this.state.useCase.name}` : `Use Case ${this.state.useCase.useCaseId}`}
                </OwcTypography>
              </span>
              <span slot="content">
                <UseCaseDetails useCase={this.state.useCase} hideDecisions/>
                <SelectedReferenceList selectedRefDataList={this.state.useCase.purposeList} title="Purpose Classification:"/>
              </span>
            </OwcExpandable>
            <OwcExpandable variant="standard" round
                expanded={this.state.isDecisionRecordExpanded}
                onExpandedChange={(ev) => this.setState({ isDecisionRecordExpanded: ev.detail })} >
              <span slot="title">
                <OwcTypography variant="title5">Decision Record</OwcTypography>
              </span>
              <span slot="content">
                  {this.renderDecisionRecord()}
                  <OwcInput
                      key={"DRNotes"}
                      style={{ display: "inline-block"}}
                      rows={this.state.decisionNotes == null ? 4 : Math.max(4, this.state.decisionNotes.split(/\r\n|\r|\n/).length)}
                      cols="100"
                      label={"Descision Notes"}
                      value={this.state.useCase.decisionNotes}
                      onValueChange={(ev) => {
                        const newCase = {...this.state.useCase};
                        newCase.decisionNotes = ev.detail;
                        if (this.state.useCase.decisionNotes !== newCase.decisionNotes) {
                          this.setState({useCase: newCase,
                            submissionState: this.UNSAVED_CHANGES_MESSAGE,
                          }, this.props.onUnsavedChangesChange(true));
                        }
                      }}
                      type="textarea"
                      no-clear-icon resizable="false">
                    <OwcAssistiveText>
                      Enter the governance board name and/or members, and any notes supporting the decision
                    </OwcAssistiveText>
                  </OwcInput>
              </span>
            </OwcExpandable>
            </OwcExpandableGroup>
            <div style={{ display:"flex", flexDirection:"column" }}>
              <table width="100%">
                <tbody>
                  <tr>
                    <td style={{verticalAlign:"top"}} align="left">
                      <OwcButton elevated style={{ width: "fit-content" }}
                          onclick={() => this.handleCancelClick()}>
                        Clear Unsaved Changes
                      </OwcButton>
                    </td>

                    <td style={{verticalAlign:"top"}} align="right">
                      <OwcButton style={{ width: "fit-content" }}
                          disabled={((submissionState === null)
                                      || (submissionState === "Saving ...")
                                      || (submissionState.includes("Successfully"))) ? true : false}
                          onclick={() => this.handleSubmitClick()}>
                        {submissionState === "Saving ..." ? submissionState : "Save Changes"}
                      </OwcButton>
                    </td>
                  </tr>
                </tbody>
              </table>
              <OwcTypography variant="title6" style={{ marginBottom: 8, textAlign: "right", color: messageColour }}>
                {submissionState === "Saving ..." ? "" : submissionState}
              </OwcTypography>
            </div>
          </div>
        );
      }
    }
  }
}

export default RecordDecisionUseCase;
